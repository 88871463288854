import { getDayOfWeekShort, IHolidayHours, Store } from '../../types/store';
import { getStartEndTime } from './utils';

export default class StoreStatus {
    public storeStatus: string;
    public hoursText: string;
    public statusText: string;
    public storeMenu: string;
    private store: Store;
    public currentTime: string;

    constructor(store: Store) {
        this.storeStatus = '';
        this.hoursText = '';
        this.statusText = '';
        this.storeMenu = '';
        this.store = store;
        this.currentTime = 'null';

        this.setStoreStatus();
        this.buildStoreMenu();
    }

    private parseStoreHours = (hours: number, minutes: number) => {
        var minuteString = '';

        if (minutes > 10) {
            minuteString += minutes;
        } else {
            minuteString += '0' + minutes;
        }

        return `${+(hours > 12 ? hours - 12 : hours)}${minuteString !== '00' ? `:${+minutes}` : ' '} ${
            hours >= 12 ? 'PM' : 'AM'
        }`;
    };

    private setStoreStatus() {
        const { Hours, HolidayHours } = this.store;
        const today = new Date();
        const dayOfWeek = today.getDay();
        const currentTime = today.getTime();
        const todaysHours = Hours.find(
            ({ startDayOfWeek, endDayOfWeek }) => dayOfWeek >= startDayOfWeek && dayOfWeek <= endDayOfWeek
        );

        if (HolidayHours) {
            const currentHolidayDate = HolidayHours.find(
                ({ date }: IHolidayHours) => new Date(date).getTime() === today.setHours(0, 0, 0, 0)
            );

            if (currentHolidayDate) {
                this.currentTime = new Date(currentTime).toString()
                let { startTime: startTime, endTime: endTime } = currentHolidayDate as any;
                startTime = new Date(startTime);
                endTime = new Date(endTime);
                const displayStartTime = this.parseStoreHours(startTime.getHours(), startTime.getMinutes());
                const displayEndTime = this.parseStoreHours(endTime.getHours(), endTime.getMinutes());
                if(startTime && endTime) {
                    if(currentTime < startTime.getTime()) {
                        this.statusText = `Holiday Hours`;
                        this.hoursText = `Opening: ${displayStartTime} - ${displayEndTime}`;
                    } else if(currentTime > endTime.getTime()) {
                        this.statusText = `Holiday Hours`;
                        this.hoursText = `Closed`;
                    } else {
                        this.statusText = `Holiday Hours`;
                        this.hoursText =  `Today: ${displayStartTime} - ${displayEndTime}`;
                    }
                }
            }

            if (Hours && !this.hoursText && todaysHours) {
                this.hoursText = `Today: ${this.parseStoreHours(
                    getStartEndTime(todaysHours.startHour).hours,
                    getStartEndTime(todaysHours.startHour).minutes
                )} - ${this.parseStoreHours(getStartEndTime(todaysHours.endHour).hours, getStartEndTime(todaysHours.endHour).minutes)}`;
            }
        }
    }

    private buildStoreMenu(): void {
        const hourMenuElements = this.store.Hours.map(
            ({ startHour: startTime, endHour: endTime, startDayOfWeek, endDayOfWeek }, index) => {
                const time = `${this.parseStoreHours(getStartEndTime(startTime).hours, getStartEndTime(startTime).minutes)} - ${this.parseStoreHours(
                    getStartEndTime(endTime).hours,
                    getStartEndTime(endTime).minutes
                )}`;
                if (startDayOfWeek === endDayOfWeek) {
                    return `<div key=${`${this.store.Id}-${index}`}>${getDayOfWeekShort(startDayOfWeek) +
                        ': ' +
                        time}</div>`;
                }
                return `<div key=${`${this.store.Id}-${index}`}>${getDayOfWeekShort(startDayOfWeek) +
                    '-' +
                    getDayOfWeekShort(endDayOfWeek) +
                    ': ' +
                    time}</div>`;
            }
        );

        this.storeMenu = hourMenuElements.join('');
    }
}
