import * as savedCarsButton from '../components/myKmx/savedCarsButton';
import * as footerCopyright from '../components/navigation/footerCopyright';
import AlertBanner from '../components/alertBanner/alertBanner';
import Events from '../events/events';
import { eventHandlerList } from '../events/eventHandlerList';
import InventorySearch from '../components/search/InventorySearch';
import MyProfileMenu from '../components/myKmx/myProfileMenu';
import MoreLinksMenu from '../components/navigation/moreLinksMenu';
import NearestStore from '../components/nearestStore/NearestStore';
import SeoLinkOptimizer, * as seo from '../utils/seo';
import StoreHourMenu from '../components/storeHours/storeHourMenu';
import * as storeChooser from '../components/storeChooser/storeChooser';
import * as searchBarTest from '../targetTests/searchBarTest';

export const start = (): void => {
    savedCarsButton.render();
    footerCopyright.render();
    storeChooser.render();

    const seoLinkOptimizer = new SeoLinkOptimizer();
    seoLinkOptimizer.optimizeServerRenderedLinks();
    let moreLinksMenu = new MoreLinksMenu(seoLinkOptimizer);
    let nearestStore = new NearestStore();
    let inventorySearch = new InventorySearch();
    nearestStore.init();
    let myProfileMenu = new MyProfileMenu();
    let storeHourMenu = new StoreHourMenu();

    let events = new Events({
            moreLinksMenu,
            nearestStore,
            myProfileMenu,
            inventorySearch,
            storeHourMenu
    });
    events.addEventHandlers(eventHandlerList);

    searchBarTest.subscribe();

    let alertBanner = new AlertBanner();
    alertBanner.init();
};
