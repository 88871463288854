interface IIndexable {
    [key: string]: any;
}

export interface INav {
    title?: string;
    menuItems: IMenuItem[];
}

export interface IMenuItem {
    title: string;
    icon?: string;
    isAccountMenu?: boolean;
    kmxAnalytics?: string;
    analytics?: IAnalytics;
    submenu?: ISubMenuItem[];
}

export interface IAttributes {
    [key: string]: string;
}

export interface ISubMenuItem {
    text?: string;
    icon?: string;
    url?: string;
    kmxAnalytics?: string;
    showLoggedIn?: boolean;
    showLoggedOut?: boolean;
    attributes?: IAttributes;
    analytics?: IAnalytics;
}

export interface IAnalytics extends IIndexable {
    linkDetails?: {
        event?: string; // defaults to ctaClick ( typeof click )
        name?: string;
    }
    searchInitiation?: {
        initiationMethod: string;
    };
    leadDetail?: {
        leadOriginationPage: string;
        leadType: string;
    };
};

export enum ThumbBarItemHighlightAction {
    'HIGHLIGHT' = 'highlight',
    'SELECT' = 'select',
    'INACTIVE' = 'inactive'
}
