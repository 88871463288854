import * as savedCarsButton from '../components/myKmx/savedCarsButton';
import * as utils from './utils';
import * as careers from '../components/navigation/CareersLink';
import InventorySearch from '../components/search/InventorySearch';
import MoreLinksMenu from '../components/navigation/moreLinksMenu';
import MyProfileMenu from '../components/myKmx/myProfileMenu';
import NearestStore from '../components/nearestStore/NearestStore';
import { content } from '../content';
import { EventTarget } from './events';
import StoreHourMenu from '../components/storeHours/storeHourMenu';
import { MENU_CLASSES } from '../components/menu/constants';

let clickedElementsList: string[] = [];

export const clickOutsideEventTargets: EventTarget[] = [
    {
        selector: `#${content.MOBILE_NAV.menuId}`,
        done: eventTargetData => {
            if (!eventTargetData.isTarget ) {
                let mobileNavOpenEl = document.querySelector(
                    `.${content.MOBILE_NAV.drawer.defaultClass}.${MENU_CLASSES.menuOpen}`
                ) as HTMLElement;

                if (mobileNavOpenEl) {
                    careers.setDesktopContent();
                }
                (eventTargetData.components['moreLinksMenu'] as MoreLinksMenu).handleCloseButtonClick();
            }
        },
    },
    {
        selector: `.${content.HEADER_MY_STORE.sectionClass}`,
        done: eventTargetData => {
            if (!eventTargetData.isTarget) {
                (eventTargetData.components['nearestStore'] as NearestStore).handleClickOutside('header');
            }
        },
    },
    {
        selector: `.${content.FOOTER_MY_STORE.sectionClass}`,
        done: eventTargetData => {
            if (!eventTargetData.isTarget) {
                (eventTargetData.components['nearestStore'] as NearestStore).handleClickOutside('footer');
            }
        },
    },
    {
        selector: `.${content.MY_PROFILE.sectionClass}`,
        done: eventTargetData => {
            if (!eventTargetData.isTarget) {
                (eventTargetData.components['myProfileMenu'] as MyProfileMenu).handleClickOutside();
            }
        },
    },
    {
        selector: `#${content.HEADER_SEARCH.id}`,
        done: eventTargetData => {
            if (!eventTargetData.isTarget) {
                (eventTargetData.components['inventorySearch'] as InventorySearch).handleClickOutside();
            }
        },
    },
];

export const headerInputEventTargets: EventTarget[] = [
    {
        selector: `#${content.HEADER_SEARCH.formId}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                (eventTargetData.components['inventorySearch'] as InventorySearch).handleInput(eventTargetData.event);
            }
        },
    },
];

export const headerKeydownEventTargets: EventTarget[] = [

    {
        selector: `#${content.MORE_LINKS.button.id}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                (eventTargetData.components['moreLinksMenu'] as MoreLinksMenu).handleOpenButtonKeydown(
                    eventTargetData.event as KeyboardEvent
                );
            }
        },
    },
    {
        selector: `#${content.MORE_LINKS.menuId}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                (eventTargetData.components['moreLinksMenu'] as MoreLinksMenu).handleMenuKeydown(
                    eventTargetData.event as KeyboardEvent
                );
            }
        },
    },
    {
        selector: `#${content.HEADER_MY_STORE.buttonId}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                (eventTargetData.components['nearestStore'] as NearestStore).handleButtonKeydown(
                    eventTargetData.event as KeyboardEvent,
                    'header'
                );
            }
        },
    },
    {
        selector: `#${content.HEADER_MY_STORE.menuId}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                (eventTargetData.components['nearestStore'] as NearestStore).handleMenuKeydown(
                    eventTargetData.event as KeyboardEvent,
                    'header'
                );
            }
        },
    },
    {
        selector: `#${content.MY_PROFILE.buttonId}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                (eventTargetData.components['myProfileMenu'] as MyProfileMenu).handleOpenButtonKeydown(
                    eventTargetData.event as KeyboardEvent
                );
            }
        },
    },
    {
        selector: `#${content.MY_PROFILE.menuId}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                (eventTargetData.components['myProfileMenu'] as MyProfileMenu).handleMenuKeydown(
                    eventTargetData.event as KeyboardEvent
                );
            }
        },
    },
    {
        selector: `#${content.HEADER_SEARCH.input.id}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                (eventTargetData.components['inventorySearch'] as InventorySearch).handleInputKeydown(
                    event as KeyboardEvent
                );
            }
        },
    },
];

export const headerSubmitEventTargets: EventTarget[] = [
    {
        selector: `#${content.HEADER_SEARCH.formId}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                (eventTargetData.components['inventorySearch'] as InventorySearch).handleSubmit(eventTargetData.event);
            }
        },
    },
];

export const headerClickEventTargets: EventTarget[] = [
    {
        selector: `.${content.HEADER_FOOTER_FLYOUT_MENU.storeHoursButtonClass}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                if (
                    utils.isDuplicateClickEvent(
                        clickedElementsList,
                        `.${content.HEADER_FOOTER_FLYOUT_MENU.storeHoursButtonClass}`,
                        eventTargetData.eventType
                    )
                ) {
                    return;
                }

                utils.waitToUpdateMenuState(() => {
                    (eventTargetData.components['storeHourMenu'] as StoreHourMenu).handleStoreStatusButtonClick();
                });
            }
        },
    },
    {
        selector: `#${MENU_CLASSES.backButton}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                if (
                    utils.isDuplicateClickEvent(
                        clickedElementsList,
                        MENU_CLASSES.backButton,
                        eventTargetData.eventType
                    )
                ) {
                    return;
                }
            }
        },
    },
    {
        selector: `#${content.MORE_LINKS.button.id}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                if (
                    utils.isDuplicateClickEvent(
                        clickedElementsList,
                        content.MORE_LINKS.button.id,
                        eventTargetData.eventType
                    )
                ) {
                    return;
                }
                (eventTargetData.components['moreLinksMenu'] as MoreLinksMenu).handleOpenButtonClick();
            }
        },
    },
    {
        selector: `#${content.HEADER_MY_STORE.buttonId}`,
        done: eventTargetData => {
            if (
                eventTargetData.isTarget &&
                utils.isDuplicateClickEvent(
                    clickedElementsList,
                    content.HEADER_MY_STORE.buttonId,
                    eventTargetData.eventType
                )
            ) {
                return;
            }

            (eventTargetData.components['nearestStore'] as NearestStore).toggle(eventTargetData.isTarget, 'header');
        },
    },
    {
        selector: `#${content.MY_PROFILE.buttonId}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                if (
                    utils.isDuplicateClickEvent(
                        clickedElementsList,
                        content.MY_PROFILE.buttonId,
                        eventTargetData.eventType
                    )
                ) {
                    return;
                }

                (eventTargetData.components['myProfileMenu'] as MyProfileMenu).handleOpenButtonClick();
            }
        },
    },
    {
        selector: `#${content.SAVED_CARS_BUTTON.id}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                if (
                    utils.isDuplicateClickEvent(
                        clickedElementsList,
                        content.SAVED_CARS_BUTTON.id,
                        eventTargetData.eventType
                    )
                ) {
                    return;
                }

                savedCarsButton.handleClick();
            }
        }
    },
    {
        selector: `#${content.HEADER_SEARCH.input.id}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget && eventTargetData.eventType === 'click') {
                (eventTargetData.components['inventorySearch'] as InventorySearch).handleInput(eventTargetData.event);
            }
        }
    },
    {
        selector: `#${content.HEADER.id}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget && eventTargetData.eventType === 'click') {
                (eventTargetData.components['inventorySearch'] as InventorySearch).handleClickOutside()
            }
        }
    }
];

export const headerMouseoverEventTargets: EventTarget[] = [
    {
        selector: `#${content.MORE_LINKS.button.id}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                (eventTargetData.components['moreLinksMenu'] as MoreLinksMenu).handleMouseover();
            }
        },
    },
    {
        selector: `#${content.HEADER_MY_STORE.buttonId}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                (eventTargetData.components['nearestStore'] as NearestStore).handleMouseover();
            }
        },
    },
    {
        selector: `#${content.MY_PROFILE.buttonId}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                (eventTargetData.components['myProfileMenu'] as MyProfileMenu).handleMouseover();
            }
        },
    },
];

export const footerClickEventTargets: EventTarget[] = [
    {
        selector: `#${content.FOOTER_MY_STORE.buttonId}`,
        done: eventTargetData => {
            if (
                eventTargetData.isTarget &&
                utils.isDuplicateClickEvent(
                    clickedElementsList,
                    content.FOOTER_MY_STORE.buttonId,
                    eventTargetData.eventType
                )
            ) {
                return;
            }

            (eventTargetData.components['nearestStore'] as NearestStore).toggle(eventTargetData.isTarget, 'footer');
        },
    },
];

export const footerKeydownEventTargets: EventTarget[] = [
    {
        selector: `#${content.FOOTER_MY_STORE.buttonId}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                (eventTargetData.components['nearestStore'] as NearestStore).handleButtonKeydown(
                    eventTargetData.event as KeyboardEvent,
                    'footer'
                );
            }
        },
    },
    {
        selector: `#${content.FOOTER_MY_STORE.menuId}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                (eventTargetData.components['nearestStore'] as NearestStore).handleMenuKeydown(
                    eventTargetData.event as KeyboardEvent,
                    'footer'
                );
            }
        },
    },
];

export const footerMouseoverEventTargets: EventTarget[] = [
    {
        selector: `#${content.FOOTER_MY_STORE.buttonId}`,
        done: eventTargetData => {
            if (eventTargetData.isTarget) {
                (eventTargetData.components['nearestStore'] as NearestStore).handleMouseover();
            }
        },
    },
];
