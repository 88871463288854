import { Store } from '../../types/store';
import { IAlertBannerData, IRegisterAlertBannersEventDetail, IAlertBannerNearestStoreRuleType } from './types';
import { constants } from './constants';

export const attachRegisterAlertBannersEventListener = (
    handleRegisterAlertBannersEvent: (alertBanners: IAlertBannerData[]) => any
): void => {
    document.documentElement.addEventListener(constants.REGISTER_ALERT_BANNERS_CUSTOM_EVENT, ((event: CustomEvent) => {
        const eventDetail: IRegisterAlertBannersEventDetail = event.detail;

        if (eventDetail.alertBanners && eventDetail.alertBanners.length > 0) {
            handleRegisterAlertBannersEvent(eventDetail.alertBanners);
        }
    }) as EventListener);
};

export const attachStoreChangeEventHandler = (handleStoreChangeEvent: (store: Store) => any) => {
    var event = new CustomEvent('kmxSubscribeToMyStoreModuleUpdates', {
        detail: {
            done: handleStoreChangeEvent,
        },
    });
    document.documentElement.dispatchEvent(event);
};

export const attachHistoryPushStateEventHandler = (handleHistoryPushStateEvent: () => any) => {
    let pushState = history.pushState;
    history.pushState = function() {
        (pushState as any).apply(history, arguments);
        handleHistoryPushStateEvent();
    };
};

const pathnameFilter = (pathname: string, pathnameRegexList: RegExp[]) => {
    let matchFound = false;
    pathnameRegexList.forEach(pathnameRegex => {
        if (!matchFound) {
            const match = pathname.match(pathnameRegex);
            matchFound = match && match.length > 0 ? true : false;
        }
    });
    return matchFound;
};

const includedNearestStoreIdsFilter = (nearestStoreId: Store['Id'], includedNearestStoreIds: Store['Id'][] = []) => {
    return includedNearestStoreIds.length > 0 ? includedNearestStoreIds.indexOf(nearestStoreId) > -1 : true;
};

const notExcludedNearestStoreIdsFilter = (nearestStoreId: Store['Id'], excludedNearestStoreIds: Store['Id'][] = []) => {
    return excludedNearestStoreIds.length > 0 ? excludedNearestStoreIds.indexOf(nearestStoreId) === -1 : true;
};

const nearestStoreFilter = (alertBanner: IAlertBannerData, nearestStoreId: Store['Id']): boolean => {
    return alertBanner.nearestStoreRules &&
        alertBanner.nearestStoreRules.type === IAlertBannerNearestStoreRuleType.Inclusion
        ? includedNearestStoreIdsFilter(nearestStoreId, alertBanner.nearestStoreRules.list)
        : alertBanner.nearestStoreRules &&
          alertBanner.nearestStoreRules.type === IAlertBannerNearestStoreRuleType.Exclusion
        ? notExcludedNearestStoreIdsFilter(nearestStoreId, alertBanner.nearestStoreRules.list)
        : true;
};

export const getPathname = (): string => {
    return (window as any).location.pathname;
};

export const findMatchingAlertBanners = (
    alertBanners: IAlertBannerData[],
    pathname: string,
    nearestStoreId: Store['Id']
): IAlertBannerData[] => {
    const matchingAlertBanners = alertBanners
        .filter(alertBanner => isValidAlertBanner(alertBanner))
        .filter(alertBanner => pathnameFilter(pathname, alertBanner.pathnameRegexList))
        .filter(alertBanner => nearestStoreFilter(alertBanner, nearestStoreId));

    return matchingAlertBanners;
};

export const isValidAlertBanner = (alertBanner: IAlertBannerData): boolean => {
    return alertBanner.id &&
        alertBanner.body &&
        alertBanner.previousSiblingElSelector &&
        alertBanner.pathnameRegexList &&
        alertBanner.pathnameRegexList.length > 0 &&
        alertBanner.pathnameRegexList[0].test !== undefined
        ? true
        : false;
};

export const renderTextWithStoreName = (stringWithTemplates: string, store: Store): string => {
    return stringWithTemplates.replace(constants.STORE_DISPLAY_NAME_TEMPLATE, store.Name);
};
