import * as eventTargets from './eventTargets';
import { content } from '../content';
import { EventHandler } from './events';

const clickOutsideEventHandler: EventHandler = {
    selector: 'body',
    eventType: 'click',
    targets: eventTargets.clickOutsideEventTargets,
};

const headerInputEventHandler: EventHandler = {
    selector: `#${content.HEADER.id}`,
    eventType: 'input',
    targets: eventTargets.headerInputEventTargets,
};

const headerKeydownEventHandler: EventHandler = {
    selector: `#${content.HEADER.id}`,
    eventType: 'keydown',
    targets: eventTargets.headerKeydownEventTargets,
};

const headerSubmitEventHandler: EventHandler = {
    selector: `#${content.HEADER.id}`,
    eventType: 'submit',
    targets: eventTargets.headerSubmitEventTargets,
};

const headerClickEventHandler: EventHandler = {
    selector: `#${content.HEADER.id}`,
    eventType: 'click',
    targets: eventTargets.headerClickEventTargets,
};

const headerTouchendEventHandler: EventHandler = {
    selector: `#${content.HEADER.id}`,
    eventType: 'touchend',
    targets: eventTargets.headerClickEventTargets,
};

const headerMouseoverEventHandler: EventHandler = {
    selector: `#${content.HEADER.id}`,
    eventType: 'mouseover',
    targets: eventTargets.headerMouseoverEventTargets,
};

const footerKeydownEventHandler: EventHandler = {
    selector: `#${content.FOOTER.id}`,
    eventType: 'keydown',
    targets: eventTargets.footerKeydownEventTargets,
};

const footerClickEventHandler: EventHandler = {
    selector: `#${content.FOOTER.id}`,
    eventType: 'click',
    targets: eventTargets.footerClickEventTargets,
};

const footerTouchendEventHandler: EventHandler = {
    selector: `#${content.FOOTER.id}`,
    eventType: 'touchend',
    targets: eventTargets.footerClickEventTargets,
};

const footerMouseoverEventHandler: EventHandler = {
    selector: `#${content.FOOTER.id}`,
    eventType: 'mouseover',
    targets: eventTargets.footerMouseoverEventTargets,
};

export const eventHandlerList: EventHandler[] = [
    clickOutsideEventHandler,
    headerInputEventHandler,
    headerKeydownEventHandler,
    headerSubmitEventHandler,
    headerTouchendEventHandler,
    headerMouseoverEventHandler,
    headerClickEventHandler,
    footerTouchendEventHandler,
    footerKeydownEventHandler,
    footerClickEventHandler,
    footerMouseoverEventHandler
];
