import { Store } from '../../types/store';

const zipRegEx = /^\d{5}$/;

export const buildTelephoneLink = (telephoneNumber: string) => {
    return `tel:${telephoneNumber.replace(/\D/g, '')}`;
};

export const isZipCode = (value: string) => {
    return zipRegEx.test(value);
};

export const camelToPascal = (object: any) => {
    var pascalObject: any = {};
    Object.keys(object).map(key => {
        const pascalKey = key.charAt(0).toUpperCase() + key.substring(1);
        pascalObject[pascalKey] = object[key];
    });

    return pascalObject;
};

// Haversine formula function
export function getDistanceInMiles(lat1: number, lat2: number, lon1: number, lon2: number) {
    function toRad(x: number) {
        return (x * Math.PI) / 180;
    }

    const R = 6371; // km

    const x1 = lat2 - lat1;
    const dLat = toRad(x1);
    const x2 = lon2 - lon1;
    const dLon = toRad(x2);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;

    d /= 1.60934;

    return d;
}
