import ListMenu from './ListMenu';
import { MENU_CLASSES, NORMALIZED_KEYS, normalizeKey } from './constants';

export const handleOpenButtonKeydown = (event: KeyboardEvent, menu: ListMenu) => {
    let key = normalizeKey(event.key);
    if (key) {
        switch (key) {
            case NORMALIZED_KEYS.ArrowDown:
                handleButtonArrowDown(event, menu);
                break;
            case NORMALIZED_KEYS.ArrowUp:
                handleButtonArrowUp(event, menu);
                break;
            case NORMALIZED_KEYS.Enter:
            case NORMALIZED_KEYS.Space:
                handleOpenButtonEnterSpace(event, menu);
                break;
            default:
                break;
        }
    }
};

export const handleMenuKeydown = (event: KeyboardEvent, menu: ListMenu) => {
    let key = normalizeKey(event.key);
    if (key) {
        switch (key) {
            case NORMALIZED_KEYS.ArrowDown:
                handleMenuArrowDown(event, menu);
                break;
            case NORMALIZED_KEYS.ArrowUp:
                handleMenuArrowUp(event, menu);
                break;
            case NORMALIZED_KEYS.Escape:
                handleMenuEscape(event, menu);
                break;
            case NORMALIZED_KEYS.Tab:
                handleMenuTab(event, menu);
                break;
            case NORMALIZED_KEYS.Home:
                handleMenuHome(event, menu);
                break;
            case NORMALIZED_KEYS.End:
                handleMenuEnd(event, menu);
                break;
            default:
                break;
        }
    }
};

export const handleMenuOpen = (menu: ListMenu) => {
    menu.toggleButtonEl.classList.add(MENU_CLASSES.buttonFocus);
    menu.menuEl.classList.add(MENU_CLASSES.menuOpen);
    menu.toggleButtonEl.setAttribute('aria-expanded', 'true');
};

export const handleMenuClose = (menu: ListMenu) => {
    removeListItemFocus(menu);
    menu.toggleButtonEl.classList.remove(MENU_CLASSES.buttonFocus);
    menu.menuEl.classList.remove(MENU_CLASSES.menuOpen);
    menu.toggleButtonEl.setAttribute('aria-expanded', 'false');
};

function handleButtonArrowUp(event: KeyboardEvent, menu: ListMenu) {
    event.preventDefault();
    menu.openMenu();
    if (menu.menuListEl) {
        const tabbableMenuItems = menu.menuListEl.getElementsByClassName(MENU_CLASSES.tabbableMenuItems)
        selectListItem(menu, menu.menuListEl.children[tabbableMenuItems.length - 1] as HTMLElement);
    }
}

function handleButtonArrowDown(event: KeyboardEvent, menu: ListMenu) {
    event.preventDefault();
    menu.openMenu();
    if (menu.menuListEl) {
        selectListItem(menu, menu.menuListEl.getElementsByClassName(MENU_CLASSES.tabbableMenuItems)[0] as HTMLElement);
    }
}

function handleOpenButtonEnterSpace(event: KeyboardEvent, menu: ListMenu) {
    event.preventDefault();
    if (!menu.open) {
        menu.openMenu();
        if (menu.menuListEl) {
            let focusableEl = menu.menuListEl.getElementsByClassName(MENU_CLASSES.tabbableMenuItems)[0];
            selectListItem(menu, focusableEl as HTMLElement);
        }
    } else {
        menu.closeMenu();
    }
}

function handleMenuArrowDown(event: KeyboardEvent, menu: ListMenu) {
    event.preventDefault();
    processMenuArrowDown(event.target as HTMLElement, menu);
}

function handleMenuArrowUp(event: KeyboardEvent, menu: ListMenu) {
    event.preventDefault();
    processMenuArrowUp(event.target as HTMLElement, menu);
}

function handleMenuEscape(event: KeyboardEvent, menu: ListMenu) {
    removeListItemFocus(menu);
    menu.closeMenu();
    menu.toggleButtonEl.focus();
}

function handleMenuTab(event: KeyboardEvent, menu: ListMenu) {
    removeListItemFocus(menu);
    menu.closeMenu();
}

function handleMenuHome(event: KeyboardEvent, menu: ListMenu) {
    if (menu.menuListEl) {
        event.preventDefault();
        selectListItem(menu, menu.menuListEl.children[0] as HTMLElement);
    }
}

function handleMenuEnd(event: KeyboardEvent, menu: ListMenu) {
    if (menu.menuListEl) {
        event.preventDefault();
        selectListItem(menu, menu.menuListEl.children[menu.menuListEl.children.length - 1] as HTMLElement);
    }
}

function removeListItemFocus(menu: ListMenu) {
    let selectedEl = menu.menuEl.querySelector(`.${MENU_CLASSES.menuItemFocus}`);
    if (selectedEl) {
        selectedEl.classList.remove(MENU_CLASSES.menuItemFocus);
        selectedEl.setAttribute('tabindex', '-1');
        let listItemContentEl = selectedEl.children[0];
        listItemContentEl.setAttribute('tabindex', '-1');
    }
}

function selectListItem(menu: ListMenu, el: HTMLElement) {
    removeListItemFocus(menu);
    el.classList.add(MENU_CLASSES.menuItemFocus);
    el.setAttribute('tabindex', '0');
    let listItemContentEl = el.children[0] as HTMLElement;
    listItemContentEl.setAttribute('tabindex', '0');
    listItemContentEl.focus();
}

function processMenuArrowUp(listItemEl: HTMLElement, menu: ListMenu) {
    if (menu.menuListEl) {
        let nextListItemEl =
            listItemEl.parentElement && listItemEl.parentElement.previousElementSibling
                ? listItemEl.parentElement.previousElementSibling
                : menu.menuListEl.children[menu.menuListEl.children.length - 1];
        selectListItem(menu, nextListItemEl as HTMLElement);
    }
}

function processMenuArrowDown(listItemEl: HTMLElement, menu: ListMenu) {
    if (menu.menuListEl) {
        let nextListItemEl =
            listItemEl.parentElement && listItemEl.parentElement.nextElementSibling
                ? listItemEl.parentElement.nextElementSibling
                : menu.menuListEl.getElementsByClassName(MENU_CLASSES.tabbableMenuItems)[0];
        selectListItem(menu, nextListItemEl as HTMLElement);
    }
}
