import AutocompleteListMenu from './AutocompleteListMenu';
import { MENU_CLASSES, NORMALIZED_KEYS, normalizeKey } from './constants';

export const handleInputKeydown = (event: KeyboardEvent, menu: AutocompleteListMenu) => {
    let key = normalizeKey(event.key);
    if (key) {
        switch (key) {
            case NORMALIZED_KEYS.ArrowDown:
                processArrows(event, menu);
                break;
            case NORMALIZED_KEYS.ArrowUp:
                processArrows(event, menu);
                break;
            case NORMALIZED_KEYS.Enter:
                handleInputEnter(event, menu);
                break;
            case NORMALIZED_KEYS.Escape:
                handleInputEscape(menu);
                break;
            default:
                break;
        }
    }
};

export const handleMenuOpen = (menu: AutocompleteListMenu) => {
    menu.inputEl.classList.add(MENU_CLASSES.buttonFocus);
    menu.menuEl.classList.add(MENU_CLASSES.menuOpen);
    menu.inputEl.setAttribute('aria-expanded', 'true');
};

export const handleMenuClose = (menu: AutocompleteListMenu) => {
    removeListItemFocus(menu);
    menu.inputEl.classList.remove(MENU_CLASSES.buttonFocus);
    menu.menuEl.classList.remove(MENU_CLASSES.menuOpen);
    menu.inputEl.setAttribute('aria-expanded', 'false');
};

function handleInputEnter(event: KeyboardEvent, menu: AutocompleteListMenu) {
    let selectedEl = getSelectedListItem(menu);
    if (selectedEl) {
        event.preventDefault();
        if (selectedEl.children.length > 0 && selectedEl.children[0].firstChild) {
            const label = selectedEl.children[0].firstChild.textContent as string;
            setInputValue(menu, label);
        }
    }
    menu.closeMenu();
}

function handleInputEscape(menu: AutocompleteListMenu) {
    setInputValue(menu, '');
    menu.closeMenu();
}

function removeListItemFocus(menu: AutocompleteListMenu) {
    let selectedEl = getSelectedListItem(menu);
    if (selectedEl) {
        selectedEl.classList.remove(MENU_CLASSES.menuItemFocus);
        selectedEl.setAttribute('tabindex', '-1');
    }
    else {
        return;
    }
}

function selectListItem(menu: AutocompleteListMenu, nextElement: HTMLElement) {
    removeListItemFocus(menu);
    if (nextElement) {
        nextElement.classList.add(MENU_CLASSES.menuItemFocus);
        nextElement.setAttribute('tabindex', '0');
    }
}

function processArrows(event: KeyboardEvent, menu: AutocompleteListMenu) {
    let { key } = event;
    event.preventDefault();
    const selectedEl = menu.menuEl.querySelector('.header-footer-menu-list-item.focus') as HTMLElement;
    let listItems = menu.menuEl.querySelectorAll('.header-footer-menu-list-item');
    const array = Array.prototype.slice.call(listItems);
    const focusedIndex = [...array].findIndex(listItem => selectedEl === listItem);
    let listIndex = 0;
     if (listItems && selectedEl) {
        if (focusedIndex !== -1) {
            if (key === 'ArrowUp') {
                listIndex = focusedIndex === 0 ? listItems.length - 1 : focusedIndex - 1;
            } else {
                listIndex = focusedIndex === listItems.length - 1 ? 0 : focusedIndex + 1;
            }
        }
        selectListItem(menu, listItems.item(listIndex) as HTMLElement);
    }
    else {
        selectListItem(menu, listItems.item(0) as HTMLElement);
    }
}

function getSelectedListItem(menu: AutocompleteListMenu) {
    return menu.menuEl.querySelector(`.${MENU_CLASSES.menuItemFocus}`) as HTMLElement;
}

function setInputValue(menu: AutocompleteListMenu, text: string): void {
    menu.inputEl.value = text;
    menu.inputEl.setAttribute('value', text);
}
