import { addCustomElement } from '../utils';
import { navStore, IMenuData } from "../store";

class ThumbNavBackDrop extends HTMLElement {
    constructor() {
        super();
    }
    _backdrop?: any;
    _currentState = navStore;

    closeHandler() {
        const { closeThumbNav, currentTitle } = this._currentState.getState() as IMenuData;
        closeThumbNav(currentTitle);
    }

    connectedCallback() {

        const styleTag = `
            <style>
                html body {
                    overflow:hidden;
                    height:100vh;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            </style>
        `;

        this.innerHTML = `
            ${styleTag}
            <div class="kmx-hf__menu__backdrop"></div>
        `;

        this._backdrop = this.querySelector('.kmx-hf__menu__backdrop');
        const { _backdrop } = this;
        if (_backdrop) {
            _backdrop.addEventListener('click', () => {

                const thisElement = document.querySelector('kmx-thumb-nav-back-drop')
                if (thisElement)
                    thisElement.remove();
                this.closeHandler();
                this.innerHTML = '';
                this.remove();
            });
        }

    }
    disconnectedCallback() {
        this._backdrop.removeEventListener('click', () => {

            const thisElement = document.querySelector('kmx-thumb-nav-back-drop')
            if (thisElement)
                thisElement.remove();
            this.closeHandler();
            this.innerHTML = '';
            this.remove();
        });
        this.remove();
    }
}

addCustomElement('kmx-thumb-nav-back-drop', ThumbNavBackDrop);
