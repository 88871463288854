import { constants } from './constants';
import { IDismissedAlertBanners, IDismissedAlertBannerLocalStorage } from './types';

export const getDismissedAlertBanners = (): IDismissedAlertBanners => {
    const dismissedAlertBannersLocalStorage = getDismissedAlertBannersLocalStorage();
    const unExpiredDismissedAlertBanners = getUnExpiredDismissedAlertBanners(dismissedAlertBannersLocalStorage);

    setDismissedAlertBannersLocalStorage(unExpiredDismissedAlertBanners);

    const dismissedAlertBanners = buildDismissedAlertBanners(unExpiredDismissedAlertBanners);
    return dismissedAlertBanners;
};

export const addDismissedAlertBanner = (id: string): void => {
    const dismissedAlertBannersLocalStorage = getDismissedAlertBannersLocalStorage();
    let unExpiredDismissedAlertBanners = getUnExpiredDismissedAlertBanners(dismissedAlertBannersLocalStorage);

    unExpiredDismissedAlertBanners = removeMatchingBanners(id, unExpiredDismissedAlertBanners);
    unExpiredDismissedAlertBanners.push({
        id: id,
        expires: new Date().getTime() + constants.DISMISSED_ALERT_BANNER_LOCAL_STORAGE_EXPIRATION,
    });
    unExpiredDismissedAlertBanners = limitBannerCount(unExpiredDismissedAlertBanners);

    setDismissedAlertBannersLocalStorage(unExpiredDismissedAlertBanners);
};

const buildDismissedAlertBanners = (
    dismissedAlertBannersLocalStorage: IDismissedAlertBannerLocalStorage[]
): IDismissedAlertBanners => {
    let dismissedAlertBanners: IDismissedAlertBanners = { allIds: [], byId: {} };

    dismissedAlertBannersLocalStorage.forEach((dismissedAlertBanner: IDismissedAlertBannerLocalStorage) => {
        dismissedAlertBanners.allIds.push(dismissedAlertBanner.id);
        dismissedAlertBanners.byId[dismissedAlertBanner.id] = { expires: dismissedAlertBanner.expires };
    });

    return dismissedAlertBanners;
};

const limitBannerCount = (
    dismissedAlertBannersLocalStorage: IDismissedAlertBannerLocalStorage[]
): IDismissedAlertBannerLocalStorage[] => {
    if (dismissedAlertBannersLocalStorage.length > constants.MAXIMUM_LOCAL_STORAGE_DISMISSED_BANNERS) {
        dismissedAlertBannersLocalStorage.sort(
            ({ expires: a }: IDismissedAlertBannerLocalStorage, { expires: b }: IDismissedAlertBannerLocalStorage) =>
                b - a
        );
        dismissedAlertBannersLocalStorage.pop();
    }

    return dismissedAlertBannersLocalStorage;
};

const removeMatchingBanners = (
    id: string,
    dismissedAlertBannersLocalStorage: IDismissedAlertBannerLocalStorage[]
): IDismissedAlertBannerLocalStorage[] => {
    let matchingDismissedAlertBannerIndex = -1;
    dismissedAlertBannersLocalStorage.forEach((dismissedAlertBanner: IDismissedAlertBannerLocalStorage, index) => {
        if (dismissedAlertBanner.id === id) {
            matchingDismissedAlertBannerIndex = index;
        }
    });
    if (matchingDismissedAlertBannerIndex > -1) {
        dismissedAlertBannersLocalStorage.splice(
            matchingDismissedAlertBannerIndex,
            matchingDismissedAlertBannerIndex + 1
        );
    }
    return dismissedAlertBannersLocalStorage;
};

const getUnExpiredDismissedAlertBanners = (
    dismissedAlertBanners: IDismissedAlertBannerLocalStorage[]
): IDismissedAlertBannerLocalStorage[] => {
    let currentTime = new Date().getTime(),
        unExpiredDismissedAlertBanners: IDismissedAlertBannerLocalStorage[] = [];

    dismissedAlertBanners.forEach((dismissedAlertBanner: IDismissedAlertBannerLocalStorage) => {
        if (dismissedAlertBanner.expires && dismissedAlertBanner.expires > currentTime) {
            unExpiredDismissedAlertBanners.push(dismissedAlertBanner);
        }
    });

    return unExpiredDismissedAlertBanners;
};

const getDismissedAlertBannersLocalStorage = (): IDismissedAlertBannerLocalStorage[] => {
    try {
        let serializedDismissedAlertBanners: string | null = localStorage.getItem(
            constants.DISMISSED_ALERT_BANNERS_LOCAL_STORAGE_KEY
        );

        if (serializedDismissedAlertBanners) {
            let dismissedAlertBannersLocalStorage: IDismissedAlertBannerLocalStorage[] = JSON.parse(
                serializedDismissedAlertBanners
            );

            return dismissedAlertBannersLocalStorage ? dismissedAlertBannersLocalStorage : [];
        }
        return [];
    } catch (e) {
        console.error('Error getting dismissed alert banner ids from localStorage: ', e);
        return [];
    }
};

const setDismissedAlertBannersLocalStorage = (dismissedAlertBanners: IDismissedAlertBannerLocalStorage[]): void => {
    try {
        localStorage.setItem(
            constants.DISMISSED_ALERT_BANNERS_LOCAL_STORAGE_KEY,
            JSON.stringify(dismissedAlertBanners)
        );
    } catch (e) {
        console.error('Error setting dismissed alert banner ids to localStorage: ', e);
    }
};
