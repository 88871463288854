import { IPayload } from '../../types/analytics';
import { ANALYTICS_CONSTANTS, track } from '../../utils/analytics';
import { determineSection } from '../storeChooser/storeChooserSearch';
import ListMenu from './ListMenu';

export interface IDelayedListMenu {
    handleMouseover(): void;
    render(force: boolean): void;
}

export default abstract class DelayedListMenu extends ListMenu implements IDelayedListMenu {
    private _rendered: boolean;
    menuName: string | undefined;

    constructor(toggleButtonEl: HTMLElement, menuEl: HTMLElement, menuName?: string) {
        super(toggleButtonEl, menuEl);
        this._rendered = false;
        this.menuName = menuName;
    }

    public handleOpenButtonClick(): void {
        this.render();
        super.handleOpenButtonClick();
    }

    public handleOpenButtonKeydown(event: KeyboardEvent) {
        this.render();
        super.handleOpenButtonKeydown(event);
    }

    public handleMouseover() {
        this.render();
    }

    abstract buildMenuHTML(): string;

    public render(force = false): void {
        if (!this._rendered || force) {
            this._rendered = true;
            this.menuEl.innerHTML = this.buildMenuHTML();
            let menuListEl = this.menuEl.querySelector('ul');
            this.menuListEl = menuListEl as HTMLElement;
            const location = determineSection(menuListEl as HTMLElement);
            this.menuEl.querySelectorAll('a').forEach(link=>{
                link.addEventListener('click', (e:Event)=> {
                    const clickedEl = e.currentTarget as HTMLElement;
                    const name = clickedEl.textContent;
                    const payload = {
                        event: ANALYTICS_CONSTANTS.EVENTS.click,
                        "linkDetails": {
                            "name": name,
                            "position": `${location}-${this.menuName}`
                        }
                    } as IPayload;
                    track(payload);
                })
            })
        }
    }
}
