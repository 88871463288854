import { IPayload } from '../types/analytics';
import { determineSection } from '../components/storeChooser/storeChooserSearch';
import { content } from '../content';

declare global {
    interface Window {
        _satellite: any;
    }
}

declare global {
    interface Window {
        adobeDataLayer: any;
    }
}

declare global {
    interface Window {
        digitalData: any;
    }
}

export const ANALYTICS_CONSTANTS = {
    STORE: {
        searchInitiated: 'search-stores-initiated',
        searchSuccessful: 'search-stores-successful',
        setStoreInitiated: 'set-store-initiated',
        setStoreSuccessful: 'set-store-successful',
    },
    ANIMATION:{
        animationFired: 'HP_icon_wave',
    },
    LOCATIONS: {
        header: 'Header',
        footer: 'Footer'
    },
    EVENTS: {
        click: 'ctaClick',
        getPreQualified: 'financeLeadInitiation',
        storeChange: 'storeChange',
        storeChangeFooter: 'storeChangeFooter',
        setStore: 'setMyStore',
        searchInitiated: 'searchInitiatedHeaderFooter'
    }
}

export const trackAnalyticsEvent = (eventId: string): void => {
    if (window._satellite) {
        window._satellite.track(eventId);
    }
};

const clearSourceSection = (): void => {
    if (window.digitalData && window.digitalData.sourceSection) {
        window.digitalData.sourceSection = "";
    }
};


export const track = (payload:IPayload): void => {
    // "adobeDataLayer" will appear in the console if the site in questions is using "EDDL"
    !payload?.event ? payload.event = ANALYTICS_CONSTANTS.EVENTS.click : null;
    console.log('Header Footer Tagging Payload - ', JSON.stringify(payload));
    if (typeof window.adobeDataLayer != 'undefined') {

        window.adobeDataLayer.push(payload);
    } else {
        const { event, linkDetails, storeSelected } = payload;
        // if "adobeDataLayer" does not exist, it will fallback
        const { position , name } = linkDetails;
        window.digitalData = window.digitalData || {};
        window.digitalData.lead = { position };
        window.digitalData.name = { name };
        window.digitalData.storeSelected = storeSelected;
        clearSourceSection();
        linkDetails.name && trackAnalyticsEvent(linkDetails.name);
    }
};






export const initDesktopHeaderTracking = (header: any)=> {
    // EDDL tracking solution for desktop header links
    const desktopHeaderLinks = header.querySelectorAll('.header-link');
    const desktopHeaderMoreButton = header.querySelector('#header-helpful-links-button');
    const desktopFavoriteHeaderLink = header.querySelector('#header-saved-cars-button');
    const desktopProfileIconButton = header.querySelector('#header-my-profile-button');


    const footerHeaderLogo = header.querySelector('#carmax-logo-header');
    if (footerHeaderLogo) {

        footerHeaderLogo.addEventListener('click', (e: Event) => {
            const payload = {
                "event": ANALYTICS_CONSTANTS.EVENTS.click,
                "linkDetails": {
                    // this name's requirement does not follow the typical pattern
                    "name": 'Carmax',
                    "position": ANALYTICS_CONSTANTS.LOCATIONS.header
                }
            } as IPayload;
            track(payload);
        });
    }


    if (desktopHeaderLinks) {
        desktopHeaderLinks.forEach((a: HTMLAnchorElement) => {

            if ( a.getAttribute('id') === 'carmax-logo-header') return;

            a.addEventListener('click', (e: Event) => {
                const target = e.currentTarget as HTMLAnchorElement;
                let name: string;
                let event : string;

                if( target.innerText.toLowerCase().trim() === "shop") {
                    event = ANALYTICS_CONSTANTS.EVENTS.searchInitiated;
                } else {
                    event = ANALYTICS_CONSTANTS.EVENTS.click
                }

                name = target.text;

                const payload = {
                    "event": event,
                    "linkDetails": {
                        "name": name,
                        "position": ANALYTICS_CONSTANTS.LOCATIONS.header
                    }
                } as IPayload;

                track(payload)

            });
        });
    };

    if (desktopHeaderMoreButton) {
        desktopHeaderMoreButton.addEventListener('click', (e: Event) => {

            const payload = {
                "event": ANALYTICS_CONSTANTS.EVENTS.click,
                "linkDetails": {
                    "name": 'More',
                    "position": ANALYTICS_CONSTANTS.LOCATIONS.header
                }
            } as IPayload;
            track(payload)

        });
    }

    const button = document.getElementById(content.HEADER_MY_STORE.buttonId);
    button?.addEventListener('click', (e: Event) => {
        const position = determineSection(button);
        const target = e?.currentTarget as HTMLElement;
        const parent = target.closest('.header-footer-icon-button');
        const headerStoreName = parent?.querySelector('#header-my-store-button-text');

        const payload = {
            "event": ANALYTICS_CONSTANTS.EVENTS.click,
            "linkDetails": {
                "name": headerStoreName?.textContent,
                "position" : position
            }
        } as IPayload;
        track(payload)
    });

    if (desktopFavoriteHeaderLink) {
        desktopFavoriteHeaderLink.addEventListener('click', (e: Event) => {
            const payload = {
                event: ANALYTICS_CONSTANTS.EVENTS.click,
                "linkDetails": {
                    "name": 'My Saved Cars',
                    "position": ANALYTICS_CONSTANTS.LOCATIONS.header
                }
            } as IPayload;
            track(payload);
        });
    }

    if (desktopProfileIconButton) {
        desktopProfileIconButton.addEventListener('click', (e:Event)=> {
            const payload = {
                "event": ANALYTICS_CONSTANTS.EVENTS.click,
                "linkDetails": {
                    "name": 'My Profile',
                    "position": ANALYTICS_CONSTANTS.LOCATIONS.header
                }
            } as IPayload;
            track(payload);
        });
    }
}

export const initFooterTracking = (footer: any)=> {
    // EDDL tracking solution for footer links
    const footerLinks = footer.querySelectorAll('.footer-link');


    const button = document.getElementById(content.FOOTER_MY_STORE.buttonId);
    button?.addEventListener('click', (e: Event) => {
        const position = determineSection(button);
        const target = e?.currentTarget as HTMLElement;
        const parent = target.closest('.header-footer-icon-button');;
        const footerStoreName = parent?.querySelector('#footer-my-store-button-text');

        const payload = {
            "event": ANALYTICS_CONSTANTS.EVENTS.click,
            "linkDetails": {
                "name": footerStoreName?.textContent,
                "position" : position
            }
        } as IPayload;
        track(payload)
    });

    if ( footerLinks ) {

        footerLinks.forEach((a: HTMLAnchorElement)=>{

            if ( a.closest('.footer-privacy')) return ;

            if ( a.getAttribute('id') === "carmax-logo-footer") return;

            a.addEventListener('click', (e : Event)=>{
                const target = e.currentTarget as HTMLAnchorElement;
                let name : string | undefined;
                let event = ANALYTICS_CONSTANTS.EVENTS.click;
                const isSocialLink = target.classList.contains('footer-link--social');
                const isPrequalLink = target.getAttribute('id') === 'footer-link-prequalify';

                if ( isSocialLink ) {
                    const ariaLabel = target.getAttribute('aria-label');
                    name = ariaLabel && ariaLabel || undefined
                } else {
                    name = target.text;
                }

                if ( target.text === "Browse by category" || target.text === "View all inventory") {
                    event = ANALYTICS_CONSTANTS.EVENTS.searchInitiated
                }

                const payload = {
                    "event": event,
                    "linkDetails": {
                        "name": name,
                        "position": ANALYTICS_CONSTANTS.LOCATIONS.footer
                    }
                } as IPayload;

                if ( !isPrequalLink ) {
                    track(payload)
                }

            })
        })
    }

    const footerCarMaxLogo = footer.querySelector('#carmax-logo-footer');
    if (footerCarMaxLogo) {
        footerCarMaxLogo.addEventListener('click', (e: Event) => {
            const payload = {
                "event": ANALYTICS_CONSTANTS.EVENTS.click,
                "linkDetails": {
                    // this name's requirement does not follow the typical pattern
                    "name": 'Carmax',
                    "position": ANALYTICS_CONSTANTS.LOCATIONS.footer
                }
            } as IPayload;
            track(payload);
        });
    }

    const footerPrivacyLinkOne = footer.querySelector('.footer-privacy .footer-link');
    if (footerPrivacyLinkOne) {

        footerPrivacyLinkOne.addEventListener('click', (e: Event) => {
            const payload = {
                "event": ANALYTICS_CONSTANTS.EVENTS.click,
                "linkDetails": {
                    // this name's requirement does not follow the typical pattern
                    "name": 'privacy policy',
                    "position": 'footer-privacy'
                }
            } as IPayload;
            track(payload);
        });
    }

    const footerPrequalifiedLink = footer.querySelector('#footer-link-prequalify');
    if (footerPrequalifiedLink) {

        footerPrequalifiedLink.addEventListener('click', (e: Event) => {
            const payload = {
                "event": ANALYTICS_CONSTANTS.EVENTS.getPreQualified,
                "linkDetails": {
                    "name": 'Get pre-qualified',
                    "position": 'Footer-Finance',
                },
                "leadDetails":{
                    "leadOriginationPage": 'Footer_GetPrequalified',
                    "leadType": 'Finance First Prequalification Application',
                }
            } as IPayload;
            track(payload);
        });
    }
}

