export const MENU_CLASSES = {
    aSubMenuIsOpen: 'a-sub-menu-is-open',
    buttonFocus: 'focus',
    menuOpen: 'header-footer-menu--open',
    menuItemFocus: 'focus',
    tabbableMenuItems: 'header-footer-menu-list-item',
    backButton: 'header-submenu-back-button',
};

export const MENU_DATA_ATTR = {
    dialog: 'data-dialog',
    focusStart: 'focus-start',
    focusEnd: 'focus-end',
};

export const NORMALIZED_KEYS = {
    ArrowDown: 'ArrowDown',
    ArrowUp: 'ArrowUp',
    Escape: 'Escape',
    Tab: 'Tab',
    Home: 'Home',
    End: 'End',
    Enter: 'Enter',
    Space: 'Space',
};

export const MOBILE_SIZE = 767;

export const normalizeKey = (key: string) => {
    switch (key) {
        case 'ArrowDown':
        case 'Arrow':
            return NORMALIZED_KEYS.ArrowDown;
            break;
        case 'ArrowUp':
        case 'Arrow':
            return NORMALIZED_KEYS.ArrowUp;
            break;
        case 'Escape':
        case 'Esc':
            return NORMALIZED_KEYS.Escape;
            break;
        case 'Tab':
            return NORMALIZED_KEYS.Tab;
            break;
        case 'Home':
            return NORMALIZED_KEYS.Home;
            break;
        case 'End':
            return NORMALIZED_KEYS.End;
            break;
        case 'Enter':
            return NORMALIZED_KEYS.Enter;
            break;
        case ' ':
            return NORMALIZED_KEYS.Space;
            break;
        default:
            return '';
            break;
    }
};
