import { addCustomElement, computeAttributes, determineMenuDataSource, filterAuthorizedMenuItems, updateBackDrop } from '../utils';
import { appElId } from '../constants';
import { navStore, IMenuData } from "../store";
import { ANALYTICS_CONSTANTS, track } from '../../../../ts/utils/analytics';
import { IMenuItem, INav, ISubMenuItem , } from '../types';
import { IPayload } from '../../../types/analytics';

const componentLevelPrefix = `${appElId}__menu__list__item`;

class ThumbNavMenuItem extends HTMLElement {

    constructor() {
        super();
    }

    connectedCallback() {

        const { index } = this.dataset;

        const subMenuIndex = Number(index);

        const { closeThumbNav, currentTitle, isSignedIn, activeTestVariant } = navStore.getState() as IMenuData;

        const menuData: INav = determineMenuDataSource(activeTestVariant);

        const parentMenu = menuData.menuItems.filter( parentMenu => parentMenu.title.toLowerCase().trim() === currentTitle?.toLowerCase().trim())[0] as IMenuItem;

        const thisMenuItem = parentMenu.submenu?.filter((_, currSubmenuIndex) => currSubmenuIndex === subMenuIndex)[0] as ISubMenuItem;

        const { icon, text, url, analytics, attributes, kmxAnalytics } = thisMenuItem

        const attributeString = attributes && String(attributes) !== "undefined" ? computeAttributes(attributes) : '';

        const hasIcon = String(icon) !== "undefined";

        const linkID = `${text!.split(" ").join("-").toLowerCase()}-link`;

        const show = filterAuthorizedMenuItems(isSignedIn, thisMenuItem)

        if (show) {
            this.innerHTML = `
            <div class="${componentLevelPrefix}${hasIcon ? " has-icon" : ""}">
                <a id="${linkID}" href="${url}" class="${componentLevelPrefix}__link" ${attributeString} data-kmx-analytics="${kmxAnalytics}">
                 ${hasIcon ? `<div class="${componentLevelPrefix}__link__icon">${icon !== undefined && atob(icon)}</div>` : ''}
                <span class="${componentLevelPrefix}__link__text">${text}</span>
                </a>
            </div>
        `;
        } else {
            this.innerHTML = '';
        }

        const thisLink = this.querySelector('a');

        if (thisLink) {
            console.log('@build of submenu buttons', analytics?.["event"], text)
            thisLink.addEventListener('click', () => {
                const parentMenuTitle = parentMenu.title.toLowerCase().trim();
                const computedParentMenuTitle = parentMenuTitle === "sell/trade" ? "sell" : parentMenuTitle;
                const name = String(text);
                const position = `${ANALYTICS_CONSTANTS.LOCATIONS.header}-${computedParentMenuTitle}`; // header-sell, etc.

                const payload = {
                    "event": analytics?.["event"], // menu item data may have an event other than "click", such as pre-qual or search. If undefined, track function with default to 'click'.
                    "linkDetails" : {
                        "name": name,
                        "position": position,
                    },
                    "search": analytics?.searchInitiation,
                    "leadDetails": analytics?.leadDetails
                } as IPayload
                track(payload);
            })
        }

        if (thisLink && url && url.includes('#')) {
            thisLink.addEventListener('click', () => {
                closeThumbNav(currentTitle);
                updateBackDrop(false);
            });
        }
    }
}

const KMX_THUMB_NAV_MENU_ITEM = 'kmx-thumb-nav-menu-item';

addCustomElement(KMX_THUMB_NAV_MENU_ITEM, ThumbNavMenuItem);
