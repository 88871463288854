import { defaultMarkup } from '../../../../generated_files/footerMarkup';
import { initFooterTracking } from '../utils/analytics';

class Footer extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.innerHTML = defaultMarkup(null);

        initFooterTracking(this)
    }
}

const KMX_GLOBAL_FOOTER = 'kmx-global-footer';
if (!customElements.get(KMX_GLOBAL_FOOTER)) {
    customElements.define(KMX_GLOBAL_FOOTER, Footer);
}
