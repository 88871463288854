export const isStockNumber = (val: string) => /^\d{8,8}$/.test(val);

export const updateSearchId = () => {
    function generateUUID() {
        let d = new Date().getTime();

        if (window.performance && typeof window.performance.now === 'function') {
            d += window.performance.now();
        }

        const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });

        return uuid;
    }

    const uuid = generateUUID();
    const dData = (window as any).digitalData;
    localStorage.setItem('searchID', uuid);
    if (dData && dData.user && dData.user[0] && dData.user[0].attributes) {
        dData.user[0].attributes.searchID = uuid;
    }
};

export const redirectToCarPage = (stockNumber: string, windowRef: Window = window) => {
    windowRef.location.assign('/car/' + stockNumber);
};

let debounceTimerId: number;
export const debounce = (done: () => any, interval: number) => {
    window.clearTimeout(debounceTimerId);

    debounceTimerId = window.setTimeout(() => {
        done();
    }, interval);
};
