import { createStore } from "zustand/vanilla";
import { toggleChatFob, titleAsId } from "../utils";
import { isAuthenticated } from "../../../storage/cookie";

export interface IMenuData {
    currentTitle?: string;
    isThumbNavMenuOpen?: boolean;
    thumbNavIndex?: number;
    isSignedIn: boolean;
    activeTestVariant: string | undefined;
    reservationStockNumber?: string;
    onNavItemClick: ({ title }: { title: string, prevTitle : string | undefined }) => void;
    openThumbNav: () => void;
    closeThumbNav: (prevTitle:string | undefined) => void;
    setActiveTestVariant: (variant:string) => void;
}

export const navStore = createStore((set): IMenuData => {
    return {
        currentTitle: undefined,
        isThumbNavMenuOpen: false,
        thumbNavIndex: undefined,
        isSignedIn: isAuthenticated(),
        activeTestVariant: undefined,
        onNavItemClick: ({ title, prevTitle }) => {
            if ( title !== prevTitle ) {
                set({
                    currentTitle: title, isThumbNavMenuOpen: true,
                });
            } else {
                set({
                    currentTitle: undefined, isThumbNavMenuOpen: false,
                });
            }
        },
        openThumbNav: () => {
            set({ isThumbNavMenuOpen: true });
            toggleChatFob('hidden');
        },
        closeThumbNav: (prevTitle:string|undefined) => {
            set({ isThumbNavMenuOpen: false, currentTitle: undefined });
            toggleChatFob('visible');
            const firstNavBarItem = document.querySelector(`#kmx-thumb-bar__item__button-${titleAsId(prevTitle!)}`) as HTMLElement;
            firstNavBarItem.focus();
        },
        setActiveTestVariant: (variant:string) => {
            set({ activeTestVariant : variant });
        }
    }
});
