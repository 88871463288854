import DialogMenu from './DialogMenu';
import { content } from '../../content';
import { MENU_CLASSES, MENU_DATA_ATTR, NORMALIZED_KEYS, normalizeKey } from './constants';

export const handleOpenButtonKeydown = (event: KeyboardEvent, menu: DialogMenu) => {
    let key = normalizeKey(event.key);
    if (key) {
        switch (key) {
            case NORMALIZED_KEYS.Enter:
            case NORMALIZED_KEYS.Space:
                handleOpenButtonEnterSpace(event, menu);
                break;
            default:
                break;
        }
    }
};

export const handleCloseButtonKeydown = (event: KeyboardEvent, menu: DialogMenu) => {
    let key = normalizeKey(event.key);
    if (key) {
        switch (key) {
            case NORMALIZED_KEYS.Enter:
            case NORMALIZED_KEYS.Space:
                handleCloseButtonEnterSpace(event, menu);
                break;
            default:
                break;
        }
    }
};

export const handleMenuKeydown = (event: KeyboardEvent, menu: DialogMenu) => {
    let key = normalizeKey(event.key);
    if (key) {
        switch (key) {
            case NORMALIZED_KEYS.Escape:
                handleMenuEscape(menu);
                break;
            case NORMALIZED_KEYS.Tab:
                handleMenuTab(event, menu);
                break;
            default:
                break;
        }
    }
};

export const handleMenuOpen = (menu: DialogMenu) => {
    menu.toggleButtonEl.classList.add(MENU_CLASSES.buttonFocus);
    menu.menuEl.classList.remove('visibility-hidden')
    menu.menuEl.classList.add(MENU_CLASSES.menuOpen);
    menu.toggleButtonEl.setAttribute('aria-expanded', 'true');
};

export const handleMenuClose = (menu: DialogMenu) => {
    menu.toggleButtonEl.classList.remove(MENU_CLASSES.buttonFocus);
    menu.menuEl.classList.remove(MENU_CLASSES.menuOpen);
    menu.toggleButtonEl.setAttribute('aria-expanded', 'false');
};

function handleOpenButtonEnterSpace(event: KeyboardEvent, menu: DialogMenu) {
    if (!menu.open) {
        event.preventDefault();
        menu.openMenu();
        setOpenMenuAccessibility(menu);
    }
}

function handleCloseButtonEnterSpace(event: KeyboardEvent, menu: DialogMenu) {
    if (menu.open) {
        event.preventDefault();
        menu.closeMenu();
        setCloseMenuAccessibility(menu);
    }
}

function handleMenuEscape(menu: DialogMenu) {
    if (menu.open) {
        menu.closeMenu();
    }
    setCloseMenuAccessibility(menu);
}

function setOpenMenuAccessibility(menu: DialogMenu) {
    let focusableEl = menu.menuEl.querySelector(`[${MENU_DATA_ATTR.dialog}="${MENU_DATA_ATTR.focusStart}"]`);
    if (focusableEl) {
        (focusableEl as HTMLElement).focus();
        if (menu.closeButtonEl) {
            focusableEl.removeAttribute(MENU_DATA_ATTR.dialog);
            menu.closeButtonEl.setAttribute(MENU_DATA_ATTR.dialog, MENU_DATA_ATTR.focusStart);
        }
    }
    menu.toggleButtonEl.classList.add(MENU_CLASSES.buttonFocus);
    menu.menuEl.classList.add(MENU_CLASSES.menuOpen);
    menu.toggleButtonEl.setAttribute('aria-expanded', 'true');
}

function setCloseMenuAccessibility(menu: DialogMenu) {
    menu.toggleButtonEl.focus();
    let menuFocusStartEl = document.querySelector(`#${content.MOBILE_NAV.focusStartId}`);
    if (menuFocusStartEl) {
        menuFocusStartEl.setAttribute(MENU_DATA_ATTR.dialog, MENU_DATA_ATTR.focusStart);
    }
    if (menu.closeButtonEl) {
        menu.closeButtonEl.removeAttribute(MENU_DATA_ATTR.dialog);
    }
    menu.toggleButtonEl.classList.remove(MENU_CLASSES.buttonFocus);
    menu.menuEl.classList.remove(MENU_CLASSES.menuOpen);
    menu.toggleButtonEl.setAttribute('aria-expanded', 'false');
}

function handleMenuTab(event: KeyboardEvent, menu: DialogMenu) {
    processTab(menu, event);
}

function processTab(menu: DialogMenu, event: KeyboardEvent) {
    if (menu.open && menu.menuEl && event.target) {
        let childMenuEvent = isChildMenuEvent(event);
        let dialogDataAttr = (event.target as HTMLElement).getAttribute(MENU_DATA_ATTR.dialog);

        if (!event.shiftKey) {
            if (dialogDataAttr === MENU_DATA_ATTR.focusEnd) {
                selectFirstFocusableEl(menu, event);
            }
            if (childMenuEvent) {
                selectFirstFocusableEl(menu, event);
            }
        }

        if (event.shiftKey) {
            if (dialogDataAttr === MENU_DATA_ATTR.focusStart) {
                selectLastFocusableEl(menu, event);
            }
        }
    }
}

function selectFirstFocusableEl(menu: DialogMenu, event: KeyboardEvent): void {
    event.preventDefault();
    let firstFocusableEl = menu.menuEl.querySelector(`[${MENU_DATA_ATTR.dialog}="${MENU_DATA_ATTR.focusStart}"]`);
    if (firstFocusableEl) {
        (firstFocusableEl as HTMLElement).focus();
    }
}

function selectLastFocusableEl(menu: DialogMenu, event: KeyboardEvent): void {
    event.preventDefault();
    let lastFocusableEl = menu.menuEl.querySelector(`[${MENU_DATA_ATTR.dialog}="${MENU_DATA_ATTR.focusEnd}"]`);
    if (lastFocusableEl) {
        (lastFocusableEl as HTMLElement).focus();
    }
}

function isChildMenuEvent(event: KeyboardEvent): boolean {
    let eventPath = (event as any).path || ((event as any).composedPath && (event as any).composedPath());
    let isChild = false;

    if (eventPath) {
        let openMenus = 0;

        eventPath.forEach((el: Element) => {
            if (el.classList && el.classList.contains(MENU_CLASSES.menuOpen)) {
                openMenus++;
            }
        });

        isChild = openMenus > 1;
    }

    return isChild;
}
