export interface IMenu {
    inputEl?: HTMLInputElement;
    toggleButtonEl?: HTMLElement;
    menuEl: HTMLElement;
    open: boolean;
    keyboardHandler: any;
    openMenu(): void;
    closeMenu(): void;
    handleInputKeydown(event: KeyboardEvent): void;
    handleOpenButtonClick(): void;
    handleCloseButtonClick(): void;
    handleClickOutside(): void;
    handleOpenButtonKeydown(event: KeyboardEvent): void;
    handleCloseButtonKeydown(event: KeyboardEvent): void;
    handleMenuKeydown(event: KeyboardEvent): void;
}

export default abstract class Menu implements IMenu {
    private _open: boolean;
    private _onMenuOpen?: () => any;
    private _onMenuClose?: () => any;

    constructor(onMenuOpen?: () => any, onMenuClose?: () => any) {
        this._open = false;
        if (onMenuOpen) {
            this._onMenuOpen = onMenuOpen;
        }
        if (onMenuClose) {
            this._onMenuClose = onMenuClose;
        }
    }

    abstract get menuEl(): HTMLElement;
    abstract get keyboardHandler(): any;

    public get open(): boolean {
        return this._open;
    }

    public openMenu(): void {
        this._open = true;
        (this.keyboardHandler as any).handleMenuOpen(this);
        if (this._onMenuOpen) {
            this._onMenuOpen();
        }
    }

    public closeMenu(): void {
        this._open = false;
        (this.keyboardHandler as any).handleMenuClose(this);
        if (this._onMenuClose) {
            this._onMenuClose();
        }
    }

    public handleInputKeydown(event: KeyboardEvent): void {
        (this.keyboardHandler as any).handleInputKeydown(event, this);
    }

    public handleOpenButtonClick(): void {
        this.open ? this.closeMenu() : this.openMenu();
    }

    public handleCloseButtonClick(): void {
        this._open = false;
        (this.keyboardHandler as any).handleMenuClose(this);
    }

    public handleClickOutside(): void {
        if (this.open) {
            this._open = false;
            (this.keyboardHandler as any).handleMenuClose(this);
            if (this._onMenuClose) {
                this._onMenuClose();
            }
        }
    }

    public handleOpenButtonKeydown(event: KeyboardEvent): void {
        (this.keyboardHandler as any).handleOpenButtonKeydown(event, this);
    }

    public handleCloseButtonKeydown(event: KeyboardEvent): void {
        (this.keyboardHandler as any).handleCloseButtonKeydown(event, this);
    }

    public handleMenuKeydown(event: KeyboardEvent): void {
        (this.keyboardHandler as any).handleMenuKeydown(event, this);
    }
}
