import DelayedDialogMenu from '../menu/DelayedDialogMenu';
import NearestStore from './NearestStore';
import { content } from '../../content';

interface INearestStoreMenu {
    type: 'header' | 'footer';
}

export default class NearestStoreMenu extends DelayedDialogMenu implements INearestStoreMenu {
    private _type: 'header' | 'footer';
    private _nearestStore: NearestStore;

    constructor(type: 'header' | 'footer', nearestStore: NearestStore) {
        const componentConst = type === 'header' ? 'HEADER' : 'FOOTER';
        const myStoreConst = type === 'header' ? 'HEADER_MY_STORE' : 'FOOTER_MY_STORE';
        const componentId = content[componentConst].id;
        const menuId = content[myStoreConst].menuId;

        let toggleButtonEl = document.querySelector(
            `#${(content as any)[componentConst].id} #${(content as any)[`${componentConst}_MY_STORE`].buttonId}`
        ) as HTMLElement;
        let menuEl = document.querySelector(`#${componentId} #${menuId}`) as HTMLElement;

        super(toggleButtonEl, menuEl, 'location');

        this._type = type;
        this._nearestStore = nearestStore;
    }

    get type(): 'header' | 'footer' {
        return this._type;
    }

    buildMenuHTML() {
        return content.MY_STORE_DETAILS.getInnerHtml(this._nearestStore.nearestStore, this._nearestStore.storeAvailability, this._type);
    }
}
