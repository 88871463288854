import { ISubMenuItem, IAttributes } from './types';
import { navStore } from './store';
import { IMenuData } from './store';
import { MOBILE_SIZE } from '../../components/menu/constants';
import { MR_CUTE_TEST_CUSTOM_EVENT, MYKMX_TEST_CUSTOM_EVENT } from './constants';
import { data, myKmxTestData, testData } from './data';

export const addCustomElement = (elementName = '', customElementClass: any) => {
    if (!customElements.get(elementName)) {
        if (customElementClass) {
            customElements.define(elementName, customElementClass);
        }
        else {
        }
    }
    else {
    }
}

export const filterAuthorizedMenuItems = (isSignedIn: boolean, submenuItem: ISubMenuItem) => {
    const { showLoggedIn, text } = submenuItem;
    return isSignedIn === showLoggedIn || showLoggedIn === undefined;
}

export const computeAttributes = (attributes: IAttributes): string => {
    let attributeString = '';
    for (const key in attributes) {
        const value = attributes[key];
        attributeString += ` ${key}="${value}"`
    }
    return attributeString;
}

export const toggleChatFob = (showHide: string) => {
    const el = document.getElementById('startChatFab');
    if (el) {
        if (showHide === 'visible') {
            el.style.visibility = 'visible';
        } else if (showHide === 'hidden') {
            el.style.visibility = 'hidden';
        }
    }
}

export const setMenuHeight = () => {
    const currentMenuElement = document.querySelector(`.kmx-hf__menu`) as HTMLElement;
    if (currentMenuElement) {
        setTimeout(() => {
            currentMenuElement.style.height = `${window.innerHeight - 96}px`;
        }, 50);
    }
}

export const minimizeChatWindow = () => {
    const minMaxButton = document.getElementById('embeddedChatFab');
    const chatBoxActive = document.querySelector('.chatbox-active') !== null;

    if ( chatBoxActive && minMaxButton ) {
        minMaxButton.click()
    }
}

export const manageChatFab = () => {
    enum VerticalDirections {
        Down = 'down',
        Up = 'up'
    }

    try {
        // TODO should this be in the zustand store so all components could use it?
        const isThumbNavVisible = () => {
            const desktopSizeMinWidth = window.matchMedia(`(min-width: ${MOBILE_SIZE + 1}px)`);
            return !desktopSizeMinWidth.matches;
        };

        const manageChatFabPosition = (direction: string) => {
            if (isThumbNavVisible()) {
                const initialChatFab = document.getElementById('startChatFab') as HTMLElement;
                const postInteractionChatFab = document.getElementById('embeddedChatFab') as HTMLElement;
                setPosition(initialChatFab, direction);
                setPosition(postInteractionChatFab, direction);
            }
        };

        const setPosition = (chatFabEl: HTMLElement, direction: string) => {
            if (chatFabEl) {
                if (direction === VerticalDirections.Up) {
                    chatFabEl.style.transform = 'translateY(-70px)';
                } else if (direction === VerticalDirections.Down) {
                    chatFabEl.style.bottom = '16px';
                    chatFabEl.style.transform = 'translateY(0)';
                }
            }
        };

        const targetNode = document.querySelector('body') as HTMLElement;
        const config = { attributes: true, childList: true, subtree: true };
        const callback = (mutationList: MutationRecord[]) => {
            for (const mutation of mutationList) {
                if (mutation.type === 'childList') {
                    const target = mutation.target as Element;
                    if (target.id === 'vehicle-image-gallery') {
                        const direction = mutation.addedNodes.length > 0 ? VerticalDirections.Down : VerticalDirections.Up;
                        manageChatFabPosition(direction);
                    }
                } else if (mutation.type === 'attributes') {
                    const target = mutation.target as Element;
                    if (target.className === 'fullScreenDialog') {
                        manageChatFabPosition(VerticalDirections.Down);
                    }
                    if (target.classList.contains('fullScreenDialog') && target.classList.contains('hidden')) {
                        manageChatFabPosition(VerticalDirections.Up);
                    }
                }
            }
        };

        const ob = new MutationObserver(callback);
        ob.observe(targetNode, config);
    } catch (err) {
        console.error(err);
    }
}

export const generateTopLevelHeaderTags = (title: string | undefined) => `${title ? `prop4=${title}` : ''}|prop5=Header`;

/**
 * @description adds or removes backdrop that is used behind menu.
 * @param isBackdropVisible
 */

export const updateBackDrop = (isBackdropVisible: boolean) => {
    const menu = document.querySelector('kmx-thumb-nav-menu');

    if (isBackdropVisible) {
        const backdrop = document.createElement('kmx-thumb-nav-back-drop');
        menu ? menu.append(backdrop) : null;
    }
    else {
        let backdrop;
        menu ? backdrop = menu.querySelector('kmx-thumb-nav-back-drop') : null;
        if (backdrop) {
            backdrop.remove();
        }
    }
}

export const titleAsId = (title:string) => {
    return title!.replace('/','').replace(' ','');
}

const tabbingAndArrowKeyCallback = (e: KeyboardEvent) => {

    const { currentTitle, closeThumbNav } = navStore.getState() as IMenuData;

    const menuButtonsWrapper = document.querySelector('.kmx-hf__menu__list') as HTMLElement;
    const menuButtons = Array.from(
        menuButtonsWrapper.querySelectorAll('.kmx-hf__menu__list__item__link')
    ) as HTMLElement[];
    const closeButton = document.getElementById('kmx-hf__menu__btn-close');
    const navigableMenuItems = [closeButton!, ...menuButtons];

    let keyboardEvent = <KeyboardEvent>e;
    let shift = e.shiftKey;
    let arrowUp = keyboardEvent.key === 'ArrowUp';
    let arrowDown = keyboardEvent.key === 'ArrowDown';
    let tab = keyboardEvent.key === 'Tab';

    if (arrowDown) {
        e.preventDefault();

        const focusedMenuItem = document.activeElement as HTMLElement;
        const focusedIndex = focusedMenuItem ? navigableMenuItems.indexOf(focusedMenuItem) : 0;

        if (focusedIndex + 1 < navigableMenuItems.length) {
            const nextListItem = navigableMenuItems[focusedIndex + 1];
            nextListItem.focus();
        } else {
            const startOfList = navigableMenuItems[0];
            startOfList.focus();
        }
    }

    if ((tab && !shift)) {
        e.preventDefault();

        const focusedMenuItem = document.activeElement as HTMLElement;
        const focusedIndex = focusedMenuItem ? navigableMenuItems.indexOf(focusedMenuItem) : 0;

        if (focusedIndex + 1 < navigableMenuItems.length) {
            const nextListItem = navigableMenuItems[focusedIndex + 1];
            nextListItem.focus();

        } else if ( currentTitle ) {
            const relatedTopMenuButton = document.getElementById(`kmx-thumb-bar__item__button-${titleAsId(currentTitle)}`)
            relatedTopMenuButton && relatedTopMenuButton.focus();
            closeThumbNav(currentTitle);

        } else {
        }
    }

    if (arrowUp || (shift && tab)) {
        e.preventDefault();

        const focusedMenuItem = document.activeElement as HTMLElement;
        const focusedIndex = focusedMenuItem ? navigableMenuItems.indexOf(focusedMenuItem) : 0;

        if (focusedIndex > 0) {
            const nextFocus = navigableMenuItems[focusedIndex - 1];
            nextFocus.focus();
        } else {
            const endOfList = navigableMenuItems[navigableMenuItems.length - 1];
            endOfList.focus();
        }
    }
};

export const addTabbingArrowKeyListener = () => {
    document.addEventListener('keydown', tabbingAndArrowKeyCallback);
};

export const removeTabbingArrowKeyListener = () => {
    document.removeEventListener('keydown', tabbingAndArrowKeyCallback);
};

export const determineMenuDataSource = (activeTestVariant:string | undefined) => {
    switch (activeTestVariant ) {
        case MYKMX_TEST_CUSTOM_EVENT:
            return myKmxTestData;
        case MR_CUTE_TEST_CUSTOM_EVENT:
            return testData;
        default:
            return data;
    }
}
