import * as api from '../../api/api';
import { Options } from '../../api/api';
import { getMyKmxUserId } from '../../storage/cookie';

export const fetchProfile = () => {
    const myKmxUserId = getMyKmxUserId();
    const { origin } = window.location;
    const profileUrl = origin + `/mycarmax/api/header/${myKmxUserId}/profile`; // MyCarMax BFF

    const options: Options = {
        url: profileUrl,
        withCredentials: true
    };
    api.get(
        options,
        function success(res) {
            const el = document.getElementById("mydashboard-header") as HTMLElement;
            el.innerText = res?.firstName ? `Hi ${res.firstName},` : 'Hello!';

            if (res?.firstName) localStorage.setItem('firstName', res.firstName);
        },
        function error(e) {
            console.error('Error fetching profile data: ', e);
            const el = document.getElementById("mydashboard-header") as HTMLElement;
            el.innerText = `Hello!`;
        }
    );
};
