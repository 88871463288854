import { Store } from '../../types/store';

export enum IAlertBannerIconTypes {
    Info = 'INFO',
    Cleaning = 'CLEANING',
    Close = 'CLOSE',
    Storefront = 'STOREFRONT',
}

export enum IAlertBannerTypes {
    Dark = 'DARK',
    Urgent = 'URGENT',
}

export enum IAlertBannerNearestStoreRuleType {
    Inclusion = 'INCLUSION',
    Exclusion = 'EXCLUSION',
}

export interface IAlertBannerData {
    id: string;
    body: string;
    previousSiblingElSelector: string;
    pathnameRegexList: RegExp[];
    type?: IAlertBannerTypes;
    nearestStoreRules?: {
        type: IAlertBannerNearestStoreRuleType;
        list: Store['Id'][];
    };
    dismissable?: boolean;
    icon?: IAlertBannerIconTypes;
}

export interface IRegisterAlertBannersEventDetail {
    alertBanners: IAlertBannerData[];
}

export interface IDismissedAlertBanners {
    allIds: string[];
    byId: {
        [id: string]: {
            expires: number;
        };
    };
}

export interface IDismissedAlertBannerLocalStorage {
    id: string;
    expires: number;
}
