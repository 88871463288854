import { addCustomElement } from './utils';
import "./kmx-thumb-bar";
import "./kmx-thumb-nav-menu";


const template = `
    <kmx-thumb-nav-menu>
    </kmx-thumb-nav-menu>
    <kmx-thumb-bar>
    </kmx-thumb-bar>
`;

class ThumbNav extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.innerHTML = template;
    }
}

const KMX_THUMB_NAV = 'kmx-thumb-nav';

addCustomElement(KMX_THUMB_NAV, ThumbNav);

