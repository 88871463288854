
export const PERMANENTLY_NO_FOLLOWED_LINKS =['/supplier-inclusion','/pre-qual/app']

export const NUM_OPTIMIZED_LINKS = 22 - PERMANENTLY_NO_FOLLOWED_LINKS.length;

export const ENABLE_SEO_OPTIMIZED_LINKS = true;

export const SEO_OPTIMIZED_LINKS = [
    '/car-financing/carmax-auto-finance',
    '/about-carmax',
    '/help-center#contact-us',
    'https://socialresponsibility.carmax.com/',
    'https://foundation.carmax.com',
    'https://media.carmax.com',
    'https://investors.carmax.com',
    'https://careers.carmax.com/us/en',
    '/service',
    '/help-center',
    '/car-buying-process/maxcare-service-plans',
    '/privacy-policy',
    '/privacy-policy/opt-out',
    '/home/docs/financial-privacy-policy-feb-2022.pdf',
    '/interest-based-ads',
    '/terms',
    '/faq/safety-recalls',
    '/terms#ca-disclosure',
    '/notice-of-accessibility'
];
