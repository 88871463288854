import { createStore } from "zustand/vanilla";


export interface IConfigData {
    ConfigurationSettings?: any;
    FeatureFlags?: any;
    setConfig: (config: any) => void
}

export const configStore = createStore((set): IConfigData => {
    return {
        FeatureFlags: { EnableFeatureFlag: false },
        setConfig: (config: any) => {
            const { ConfigurationSettings, FeatureFlags } = config;
            set({ ConfigurationSettings, FeatureFlags })
        }
    }
});


declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        configStore: any;
    }
}

window.configStore = configStore || {};
