import * as constants from '../constants';
import { Store } from '../types/store';
import { getDistanceInMiles } from '../components/nearestStore/utils';
import * as environment from '../utils/environment';

export const COOKIES = {
    visitor: 'KmxVisitor_0',
    myKmx: 'KmxMyKmx_0',
    store: 'KmxStore',
    prevAuthQA: `Kmx_PreviousQAuth`,
    prevAuthPROD: `Kmx_PreviousPAuth`
};

export const getStoreId = (): Store['Id'] => {
    const visitorCookies = getCookieObject(COOKIES.visitor);
    const storeId = 'StoreId';
    if(visitorCookies && visitorCookies[storeId]) {
        return visitorCookies[storeId];
    }

    return '';
};

export const getStoreZipCode = (): string => {
    const visitorCookies = getCookieObject(COOKIES.visitor);
    const key = 'Zip';
    if(visitorCookies && visitorCookies[key]) {
        return visitorCookies[key];
    }

    return '';
};

export const getUserEnteredZipCode = ():string | null => {
    const visitorCookies = getCookieObject(COOKIES.visitor);
    const key = 'StoreSearchZipCode';
    if (visitorCookies && visitorCookies[key]) {
        return visitorCookies[key];
    }
    return null;
}

export const  setCookie = (
    key: string,
    value: string,
    attributes: { domain: string; expires: number; path: string } | undefined
) => {
    const attrs: any = attributes || { domain: '.carmax.com', expires: 365, path: '/' };

    if (typeof document === 'undefined') {
        return;
    }

    if (typeof attrs.expires === 'number') {
        const expires = new Date();
        expires.setMilliseconds(expires.getMilliseconds() + attrs.expires * 864e5);
        attrs.expires = expires;
    }

    // We're using "expires" because "max-age" is not supported by IE
    attrs.expires = attrs.expires ? attrs.expires.toUTCString() : '';

    let stringifiedAttributes = '';
    for (const attributeName in attrs) {
        if (!attrs[attributeName]) {
            continue;
        }
        stringifiedAttributes += `; ${attributeName}`;
        if (attrs[attributeName] === true) {
            continue;
        }
        stringifiedAttributes += `=${attrs[attributeName]}`;
    }

    return (document.cookie = `${key}=${value}${stringifiedAttributes}`);
}

export const stringifyCookie = (cookieObject: any) => {
    let stringifiedValue = '';

    for (const key in cookieObject) {
        if (!cookieObject[key]) {
            continue;
        }
        stringifiedValue === '' ? (stringifiedValue += key) : (stringifiedValue += `&${key}`);
        if (cookieObject[key] === true) {
            continue;
        }
        stringifiedValue += `=${cookieObject[key]}`;
    }

    return stringifiedValue;
}

export const setStoreCookie = (store: Store) => {
    const visitorCookie = getCookieObject(COOKIES.visitor);
    visitorCookie.StoreId = store.Id;
    visitorCookie.Zip = store.ZipCode;
    setCookie(COOKIES.visitor, stringifyCookie(visitorCookie), undefined);
    // We need to set visitor cookie and store cookie
    const storeCookie = getCookieObject(COOKIES.store);
    storeCookie.StoreId = store.Id;
    setCookie(COOKIES.store, stringifyCookie(storeCookie), undefined)
    return visitorCookie;
}

export const getVisitorCookieLatLng = (visitorCookie: {Lat: string, Lon: string}): {Lat: number, Lon: number} | false => {
    const visitorLat: string = visitorCookie['Lat'];
    const visitorLong: string = visitorCookie['Lon'];
    if(visitorLat && visitorLong) {
        return {
            Lat: parseFloat(visitorLat),
            Lon: parseFloat(visitorLong)
        }
    } else {
        return false;
    }
}

export const getStoreDistance = (store: Store): number => {
    try {
        var visitorCookie = getCookieObject(COOKIES.visitor);
        const visitorLatLng = getVisitorCookieLatLng(visitorCookie);
        if(visitorLatLng) {
            var distance = getDistanceInMiles(store.Latitude, visitorLatLng.Lat, store.Longitude, visitorLatLng.Lon);
            return Math.round(distance * 10) / 10;
        } else {
            return -1;
        }
    } catch (e) {
        return -1;
    }
};

export const isAuthenticated = (): boolean => {
    try {
        return !!document.cookie.match(COOKIES.myKmx);
    } catch (e) {
        return false;
    }
};

export const hasSignedIn = (): boolean => {
    try {
        const authCookieName = environment.get().prod ? COOKIES.prevAuthPROD : COOKIES.prevAuthQA;
        return document.cookie.includes(`${authCookieName}=`);
    } catch (e) {
        return false;
    }
};

export const hasCustomerHubStore = (): boolean => {
    try {
        const storeId = getStoreId();
        return constants.CUSTOMER_HUB_STORES_LIST.indexOf(storeId) > -1;
    } catch (e) {
        return false;
    }
};

export const hasEpuStore = (): boolean => {
    try {
        const storeId = getStoreId();
        return constants.EXPEDITED_PICKUP_STORES_LIST.indexOf(storeId) > -1;
    } catch (e) {
        return false;
    }
};

export const hasAfterSaleDeliveryStore = (): boolean => {
    try {
        const storeId = getStoreId();
        return constants.AFTER_SALE_DELIVERY_STORES_LIST.indexOf(storeId) > -1;
    } catch (e) {
        return false;
    }
};

export const hasDeliveryStore = (): boolean => {
    try {
        const storeId = getStoreId();
        return constants.DELIVERY_STORES_LIST.indexOf(storeId) > -1;
    } catch (e) {
        return false;
    }
};

export const hasCurbsideStore = (): boolean => {
    try {
        const storeId = getStoreId();
        return constants.CURBSIDE_STORES_LIST.indexOf(storeId) > -1;
    } catch (e) {
        return false;
    }
};

export function getCookieObject(cookieName: string) {
    var value = '; ' + document.cookie;
    var parts = value.split('; ' + cookieName + '=');
    var cookie = '';
    if (parts.length > 1) {
        var splitParts = parts.pop();
        cookie = (splitParts && splitParts.split(';').shift()) || '';
    }

    var cookieObj: any = {};
    cookie.split('&').forEach(function(value) {
        var keyValue = value.split('=');
        if (keyValue[0] && keyValue[1] !== 'undefined') {
            cookieObj[keyValue[0]] = keyValue[1];
        }
    });
    return cookieObj;
}

export const getMyKmxUserId = (): string => {
    const cookieName = `${COOKIES.myKmx}=userid=`;
    if (document.cookie.includes(cookieName)) {
        return document.cookie.split(cookieName)[1].split(';')[0];
    }
    return '';
}
