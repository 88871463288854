import InventorySearch from '../components/search/InventorySearch';
import MoreLinksMenu from '../components/navigation/moreLinksMenu';
import MyProfileMenu from '../components/myKmx/myProfileMenu';
import NearestStore from '../components/nearestStore/NearestStore';
import StoreHourMenu from '../components/storeHours/storeHourMenu';

interface IComponentsMap {
    [componentName: string]:
        | NearestStore
        | MoreLinksMenu
        | MyProfileMenu
        | InventorySearch
        | StoreHourMenu;
}
export type ComponentsMap = IComponentsMap;

interface IEventTargetData {
    isTarget: boolean;
    eventType: string;
    event: Event | TouchEvent;
    components: ComponentsMap;
    style?:any;
}
export type EventTargetData = IEventTargetData;

interface IEventTarget {
    selector: string;
    exceptions?: string[] | any;
    done: (eventTargetData: EventTargetData) => any;
}
export type EventTarget = IEventTarget;

export interface IEventHandler {
    selector: string;
    eventType: string;
    targets: EventTarget[];
}
export type EventHandler = IEventHandler;

export interface IEvents {
    components: ComponentsMap;
    addEventListenerWithDelegation(eventHandler: EventHandler, eventHandlerEl: Element): void;
    addEventHandlers(eventHandlerList: EventHandler[]): void;
}

export default class Events implements IEvents {
    components: IEvents['components'];

    constructor(components: IEvents['components']) {
        this.components = components;
    }

    isException(target:HTMLElement,exceptions: Array<string>) {
        if ( exceptions ) {
            return exceptions.some(i=>{
                return target.closest(i)
            })
        }
    }

    addEventListenerWithDelegation(eventHandler: EventHandler, eventHandlerEl: Element | Window) {
        const eventHandlerElement = eventHandlerEl as Element;
        eventHandlerElement.addEventListener(eventHandler.eventType, e => {
            if(eventHandler.selector === "window"){
                eventHandler.targets[0].done({
                    isTarget: true,
                    eventType: eventHandler.eventType,
                    event: e,
                    components: this.components,
                });
            }
            else{
                for (let i = 0; i < eventHandler.targets.length; i++) {
                    let isTarget = !!(e && e.target && (<HTMLElement>e.target).closest(eventHandler.targets[i].selector)|| this.isException(<HTMLElement>e.target,eventHandler.targets[i].exceptions));
                    eventHandler.targets[i].done({
                        isTarget: isTarget,
                        eventType: eventHandler.eventType,
                        event: e,
                        components: this.components,
                    });
                }
            }
        });
    }

    addEventHandlers(eventHandlerList: EventHandler[]): void {
        eventHandlerList.forEach((eventHandler: EventHandler) => {
            if(eventHandler.selector === "window"){
                this.addEventListenerWithDelegation(eventHandler, window);
            }
            else{
                const eventHandlerEl = document.querySelector(eventHandler.selector);
                if (eventHandlerEl) {
                    this.addEventListenerWithDelegation(eventHandler, eventHandlerEl);
                }
            }

        });
    }
}
