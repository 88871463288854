import * as api from '../../api/api';
import { Options } from '../../api/api';
import { SearchResults, GetURLParams } from '../../types/search';
import { COOKIES, isAuthenticated } from '../../storage/cookie';


export type SearchSuggestionsApiResponse = SearchResults | null;

export const SEARCH_SUGGESTIONS_API = {
    uri: '/api/cars/suggestions?keyword='
};

const getUserId = () => {
    if (isAuthenticated()) {
        const myKmxUserId = document.cookie.split(`${COOKIES.myKmx}=`)[1].split(';')[0];
        return `&${myKmxUserId}`;
    } else {
        return ''
    }
};

export const getUrl = ({ search, params = '', userId }: GetURLParams): string =>
    SEARCH_SUGGESTIONS_API.uri + encodeURIComponent(search) + (params ? `&${params}` : '') + (userId ? userId : '');

export const getSearchSuggestions = (
    search: string,
    params: string,
    done: (searchSuggestionsApiResponse: SearchResults | null) => any
) => {
    const options: Options = {
        url: getUrl({ search, params, userId: getUserId() }),
        withCredentials: true
    };
    api.get(
        options,
        function success(res) {
            done(res);
        },
        function error(e) {
            console.error('Error fetching search suggestions: ', e);
            done(null);
        }
    );
};
