import { content } from '../../content';
import { buildStoreChooserHeader } from './storeChooserHeader';
import { buildStoreSearchFormAndResults } from './storeChooserSearch';
import { closeModal } from './utils';


export const render = () => {
    // Build wrapper element of store chooser
    const storeChooserWrapperHTML: HTMLElement = document.createElement('div');
    storeChooserWrapperHTML.classList.add(content.STORE_CHOOSER.WRAPPER.class);
    storeChooserWrapperHTML.id = content.STORE_CHOOSER.WRAPPER.id;
    // create a sem-transparent background
    const modalBackgroundHTML: HTMLElement = document.createElement('div');
    modalBackgroundHTML.classList.add(content.STORE_CHOOSER.BACKGROUND.class);
    modalBackgroundHTML.addEventListener('click', () => closeModal());
    // create the modal for users to interact with
    const storeChooserModalHTML: HTMLElement = document.createElement('div');
    storeChooserModalHTML.classList.add(content.STORE_CHOOSER.MODAL.class);
    storeChooserModalHTML.id = content.STORE_CHOOSER.MODAL.id;
    storeChooserModalHTML.setAttribute('role', content.STORE_CHOOSER.MODAL.role);
    storeChooserModalHTML.setAttribute('aria-labelledby', content.STORE_CHOOSER.MODAL.HEADER.TITLE.id);
    storeChooserModalHTML.setAttribute('aria-describedby', content.STORE_CHOOSER.MODAL.HEADER.DESCRIPTION.id);
    storeChooserModalHTML.addEventListener('keyup', (e: KeyboardEvent) => {
        if(e.key === 'Escape') closeModal();
    });
    // Append the header and search form
    storeChooserModalHTML.appendChild(buildStoreChooserHeader());
    storeChooserModalHTML.appendChild(buildStoreSearchFormAndResults());
    // add a body element for the search results
    const storeChooserModalBodyHTML: HTMLElement = document.createElement('div');
    storeChooserModalBodyHTML.classList.add(content.STORE_CHOOSER.MODAL.BODY.class);
    storeChooserModalBodyHTML.id = content.STORE_CHOOSER.MODAL.BODY.id;
    storeChooserModalHTML.appendChild(storeChooserModalBodyHTML)
    storeChooserWrapperHTML.appendChild(modalBackgroundHTML);
    storeChooserWrapperHTML.appendChild(storeChooserModalHTML);
    // make sure the header is there before we try and slide this to be the first child element
    const headerElement: HTMLElement | null = document.querySelector(`#${content.HEADER.id}`);
    if(headerElement) { 
        headerElement.prepend(storeChooserWrapperHTML);
    }
}
