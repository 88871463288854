
import { addCustomElement, filterAuthorizedMenuItems, setMenuHeight, addTabbingArrowKeyListener, removeTabbingArrowKeyListener } from '../utils';
import { appElId } from '../constants';
import { navStore, IMenuData } from "../store";
import { data, testData, myKmxTestData } from '../data';
import { icons } from '../icons';
import { IMenuItem, ISubMenuItem } from '../types';
import './kmx-thumb-nav-menu-item';
import './kmx-back-drop';
import { MR_CUTE_TEST_CUSTOM_EVENT, MYKMX_TEST_CUSTOM_EVENT } from '../constants';
import { isAuthenticated } from '../../../storage/cookie';
import { fetchProfile } from '../../../components/myKmx/profileApi';
import { getReservation } from '../../../components/myKmx/reservationApi';

const componentLevelPrefix = `${appElId}__menu`;

const template = (isSignedIn: boolean) => {
    return `
        <div id="subMenuContainer" role="menu" class="${componentLevelPrefix} ${isSignedIn ? 'signed-in' : ''} ">
            <button tabindex="-1" id="${componentLevelPrefix}__btn-close" role="menuitem" class="${componentLevelPrefix}__btn-close" aria-label="Close menu">${atob(icons.close)}</button>
            <div id="kmx-thumb-nav-menu-content"></div>
        </div>
    `
}

const computeHeadingText = (currentTitle: string | undefined, currentItem: IMenuItem) => {
    if (currentItem && currentItem.isAccountMenu) {
        return 'Hello!';
    } else {
        return currentTitle;
    }
}

const computeMyKmxHeadingText = (currentTitle: string | undefined, currentItem: IMenuItem) => {
    if (currentItem && currentItem.isAccountMenu && isAuthenticated()) {
        getReservation();
        let firstName = localStorage.getItem('firstName');

        if (!firstName) {
            fetchProfile();
            return `<div class="mydashboard-loader"></div>`
        }

        return `Hi ${firstName},`;
    } else {
        return currentTitle;
    }
}

const buildThumbNavMenuItem = (index: number) => {
    // do not have a way to
    return `
        <kmx-thumb-nav-menu-item data-index="${index}">
        </kmx-thumb-nav-menu-item>
    `;
}

const menuContent = (subMenuItems: ISubMenuItem[] | undefined, state: IMenuData | null) => {

    if (state) {
        const { isSignedIn, currentTitle, activeTestVariant } = state;
        if (currentTitle === undefined) {

            return '';

        } else {
            const currentItem = (activeTestVariant === MYKMX_TEST_CUSTOM_EVENT)
                ? myKmxTestData.menuItems.find((item) => item.title.toLocaleLowerCase() === (currentTitle as string).toLocaleLowerCase())
                : data.menuItems.find((item) => item.title.toLocaleLowerCase() === (currentTitle as string).toLocaleLowerCase());

            const html = subMenuItems && subMenuItems
                .map((_, index) => {
                    return buildThumbNavMenuItem(index)
                }).join('');

            if (activeTestVariant === MYKMX_TEST_CUSTOM_EVENT) {
                return `
                    <header class="${componentLevelPrefix}__title" id="mydashboard-header">${computeMyKmxHeadingText(currentTitle, (currentItem as IMenuItem))}</header>
                    <div id="${currentTitle.split(' ').join('')}" class="${componentLevelPrefix}__list">
                        ${html}
                    </div>
                `
            }

            return `
                <header class="${componentLevelPrefix}__title">${computeHeadingText(currentTitle, (currentItem as IMenuItem))}</header>
                <div id="${currentTitle.split(' ').join('')}" class="${componentLevelPrefix}__list">
                    ${html}
                </div>
            `
        }
    } else {
        return '';
    }

};

class ThumbNavMenu extends HTMLElement {
    constructor() {
        super();
    }

    _currentState = navStore;
    _initialState = navStore.getState() as IMenuData;
    _button?: HTMLElement;
    _backDropElement: string = 'kmx-thumb-nav-back-drop';

    /**
     * @description closes menu
     */
    closeHandler() {
        const { closeThumbNav, currentTitle } = this._currentState.getState() as IMenuData;
        closeThumbNav(currentTitle);
        this.setMenuContent(undefined, false)
    }

    /**
     * @description adds or removes backdrop that is used behind menu.
     * @param isBackdropVisible
     */
    updateBackDrop(isBackdropVisible: boolean) {
        const backDropElement = this._backDropElement;
        if (isBackdropVisible) {
            const backdrop = document.createElement(backDropElement);
            this.append(backdrop);
            const backdropInnerEl = this.querySelector('.kmx-hf__menu__backdrop') as HTMLElement
            setTimeout(() => {
                backdropInnerEl.classList.add('mobile-backdrop--visible');
            }, 1);
            const closeButton = this.querySelector('#kmx-hf__menu__btn-close');
        }
        else {
            const backdrop = this.querySelector(this._backDropElement);
            if (backdrop) {
                const backdropInnerEl = this.querySelector('.kmx-hf__menu__backdrop') as HTMLElement
                backdropInnerEl.addEventListener('transitionend', (e) => {
                    backdropInnerEl.parentElement!.remove();
                });
                backdropInnerEl.classList.remove('mobile-backdrop--visible');
            }
        }
    }

    connectedCallback() {
        this.updateMenuItems();
        this.innerHTML = template(this._initialState.isSignedIn);
        this.closeListener();
        localStorage.removeItem("firstName");
    }

    updateMenuItems() {
        this._currentState.subscribe((state: any, prev: any) => {
            if (state.currentTitle !== undefined) {
                if (state.activeTestVariant === MR_CUTE_TEST_CUSTOM_EVENT) {
                    const testSubMenuItems = testData.menuItems.filter(item => item.title === state.currentTitle)[0].submenu;
                    this.setMenuContent(testSubMenuItems, state);
                }
                else if (state.activeTestVariant === MYKMX_TEST_CUSTOM_EVENT) {
                    const testSubMenuItems = myKmxTestData.menuItems.filter(item => item.title === state.currentTitle)[0].submenu;
                    this.setMenuContent(testSubMenuItems, state);
                }
                else {
                    const subMenuItems = data.menuItems.filter(item => item.title === state.currentTitle)[0].submenu;
                    this.setMenuContent(subMenuItems, state);
                }
            }
            this.toggleMenu(state, prev);
        })
    }

    /**
     * @description: animates menu based on app state
     */
    toggleMenu(state: any, prev: any) {
        const menuElement = document.querySelector(`.${componentLevelPrefix}`) as HTMLElement;
        if (state.currentTitle !== undefined) {
            addTabbingArrowKeyListener();
            document.addEventListener('keyup', this.escKeyupCallback)
        } else {
            this.setMenuContent(undefined, false);
            removeTabbingArrowKeyListener();
            document.removeEventListener('keyup', this.escKeyupCallback);
        }

        if (state.isThumbNavMenuOpen && !prev.isThumbNavMenuOpen) {
            this.updateBackDrop(true);
            if (menuElement) {
                const closeButton = menuElement.querySelector('#kmx-hf__menu__btn-close');
                window.addEventListener('orientationchange', setMenuHeight);
                setTimeout(() => {
                    menuElement.classList.add('menu--fade-in');
                    closeButton && closeButton.setAttribute('tabindex', '0');
                    setMenuHeight();
                }, 0);
            }
        }

        if (!state.isThumbNavMenuOpen) {
            this.updateBackDrop(false);
            if (menuElement) {
                setTimeout(() => {
                    menuElement.style.height = '0px';
                    menuElement.classList.remove('menu--fade-in');
                }, 0);
                window.removeEventListener('orientationchange', setMenuHeight);
            }
        }
    }

    setMenuContent(subMenuItems: ISubMenuItem[] | undefined, state: any) {
        const contentContainer = this.querySelector('#kmx-thumb-nav-menu-content');
        if (contentContainer) {
            if (!subMenuItems) {
                contentContainer.innerHTML = '';
            } else {
                contentContainer.innerHTML = menuContent(subMenuItems, state);
            }
        }
    }

    escKeyupCallback = (e: KeyboardEvent) => {
        const closeButton = this.querySelector(`#${componentLevelPrefix}__btn-close`);
        let keyboardEvent = <KeyboardEvent>e;
        if (keyboardEvent.key === "Escape") {
            this.closeHandler();
            closeButton && closeButton.setAttribute('tabindex', '-1');
        }
    }

    closeListener() {
        const closeButton = this.querySelector(`#${componentLevelPrefix}__btn-close`);

        if (closeButton) {

            closeButton.removeEventListener('click', () => {
                this.closeHandler();
                this.updateBackDrop(false);
            });

            closeButton.addEventListener('click', () => {
                this.closeHandler();
            });

        }
    }

    disconnectedCallback() {
        console.log('disconnect');
    }
}

const KMX_THUMB_NAV_MENU = 'kmx-thumb-nav-menu';

addCustomElement(KMX_THUMB_NAV_MENU, ThumbNavMenu);


