import '../scss/index.scss';
import * as app from './app/app';
import './webcomponents/wc-header';
import './webcomponents/wc-footer';
import './webcomponents/exposed-nav/kmx-thumb-nav';
import { appElId } from './webcomponents/exposed-nav/constants';
import { configStore, IConfigData } from "./configStore";

async function getFeatureFlags() {
    const { setConfig } = configStore.getState() as IConfigData;
    const response = await fetch('/shared/api/config/header-footer');
    const config = await response.json();
    setConfig(config);
}

getFeatureFlags();

app.start();

const kmx_thumb_nav = document.createElement('kmx-thumb-nav');
kmx_thumb_nav.id = appElId;
document.body.prepend(kmx_thumb_nav);
const skipToMainContentLink = document.createRange().createContextualFragment(`<a id="skipMainContent" href="#main-content-heading" class="skip-nav">Skip to main content</a>`)
document.body.prepend(skipToMainContentLink)
