//Do Not Update this file: the contents of this file are generated via a script
 export const defaultMarkup = (theme:string | null)=>`
<a id="skipMainContent" href="#main-content-heading" class="skip-nav">Skip to main content</a>
<header id="header" class="${theme}" role="banner">
    <nav class="header-nav" aria-label="primary">
        <!-- Left nav -->
        <section class="header-nav-left">
            <a id="carmax-logo-header" href="/" class="header-link" data-kmx-analytics="prop5=Header" aria-label="CarMax home page">
                <!-- CarMax logo -->
                <svg class="header-main-logo" viewBox="0 0 327.84 64"
                    xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path fill="#053361"
                        d="M289.8 5.05a1.1 1.1 0 0 0 .85-1.12c0-1.17-1.23-1.17-1.57-1.17h-1.65V7.4h.64V5.3h.8c.76 0 .94.2 1 .9a5.26 5.26 0 0 0 .23 1.2h.66a6.74 6.74 0 0 1-.28-1.46c-.05-.57-.2-.77-.7-.9zm-.72-.3h-1V3.3h1c.34 0 .92 0 .92.74s-.6.7-.92.7zM289 .8a4.33 4.33 0 1 0 4.32 4.32A4.34 4.34 0 0 0 289 .8zm0 8.2a3.83 3.83 0 1 1 3.8-3.84A3.82 3.82 0 0 1 289 9z">
                    </path>
                    <path fill="#053361"
                        d="M129.42 54.55h32.32V64h-32.32v-9.45zm41.53 0V64h32.32v-9.45zm41.52 0V64h32.32v-9.45zm41.52 0V64h32.3v-9.45zm41.52 0V64h32.32v-9.45z">
                    </path>
                    <path fill="#053361"
                        d="M129.57 42.43V1.23h16.26l.4 6.24h.15c2.82-5.9 7-7.47 10.88-7.47 5.3 0 9.16 2.38 11.3 7.47 2.82-5.9 7-7.47 10.88-7.47 7 0 12 4.1 12 15v27.43h-16.7V16.58c0-2.87-1.3-4.43-2.88-4.43s-3 1.3-3 4.6v25.68h-16.7V16.58c0-2.87-1.3-4.43-2.9-4.43s-3 1.3-3 4.6v25.68zm138.26 0h16.75l-11.48-22 9.35-19H265l-4.64 13-5.6-13H237.3l10.16 19-11.15 22h16.95l7.1-15 7.48 15zm-48.7-14.2V24.8h-2.6c-5.1 0-6.2 2.46-6.2 4.74 0 2.62 1.5 4.25 4.44 4.25 3.34 0 4.37-2.2 4.37-5.56zm0-11.32v-4.23c0-2-1-3.27-3.72-3.27-2.37 0-3.65.82-3.88 4.25h-15.86c.64-11 10.5-13.4 20.57-13.4 15.33 0 20.25 5 20.25 14.54v27.6h-16.85l-.5-3.88c-2.78 3.76-7 5.15-11.36 5.15-8.1 0-13.8-4.4-13.8-12.5 0-10.12 7.93-14.3 22.86-14.3z">
                    </path>
                    <path fill="#053361"
                        d="M25.18 27.16H40.5c0 11.38-9.1 16.35-19.32 16.35C6.7 43.5 0 34.7 0 21.6-.38 8.75 7.28.06 21.23.06c10.4 0 19.22 4.23 19.28 16.36h-15c-.22-4.68-1.93-5.6-3.93-5.6-4.24 0-4.64 4.35-4.64 11 0 6.3.35 11 4.46 11 2.1 0 3.54-1.26 3.77-5.6zm78.18-8.77h1.37c3.15 0 5.43-1.1 5.43-4.18s-2.1-3.66-5.3-3.66h-1.5v7.83zm-16 24V1.2h24.4c8.84 0 14 2.68 14 10.45 0 4.74-2 8.34-8.18 9.94 4 .33 6.17 2.1 7 5.24.9 3.54 1.3 8.08 2.07 15.6h-16c-1.5-9.25-.88-8.3-1.45-11.83-.35-2.05-1.37-2.85-4-2.85h-1.9v14.68zM58.53 25.72h7l-3.4-15.55-3.6 15.55zm-19.8 16.7L52 1.13h21.17l13.27 41.3H69.5l-1.6-6.64H56.08l-1.66 6.63z">
                    </path>
                </svg>
            </a>
            <!--  menu flyout -->
            <div class="header-drawer-container">
                <aside class="header-drawer" id="header-nav-menu" title="header-drawer">
                    <div class="header-drawer-body">
                        <a id="header-drawer-focus-start" data-dialog="focus-start" href="/cars"
                            class="header-link" data-kmx-analytics="prop5=Header | searchInit">Shop</a>
                        <a id="header-sell-link" href="/sell-my-car" class="header-link" data-kmx-analytics="prop5=Header">Sell/Trade</a>
                        <a id="header-finance-link" href="/car-financing" class="header-link" data-kmx-analytics="prop5=Header">Finance</a>
                        <!-- More links menu -->
                        <section class="header-helpful-links-section">
                            <button id="header-helpful-links-button" aria-haspopup="true"
                                aria-controls="header-helpful-links-menu" aria-label="Open buy links menu"
                                class="header-footer-icon-button" type="button" data-kmx-analytics="prop5=Header">
                                <span class="header-helpful-links-title">More</span>
                                <!-- Arrow drop down icon -->
                                <svg class="header-footer-icon-button-icon mobile-hidden" width="16px"
                                    height="16px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true">
                                    <path d="M7 10l5 5 5-5z"></path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>

                                <!-- Keyboard arrow drop down icon -->
                                <svg class="header-helpful-links-button-icon tablet-hidden desktop-hidden" height="24" width="24"
                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
                                    <path d="M0-.75h24v24H0z" fill="none"></path>
                                </svg>
                            </button>
                            <div class="header-footer-menu-container">
                                <div id="header-helpful-links-menu" role="menu"
                                    aria-labelledby="header-helpful-links-button" class="header-footer-menu"></div>
                            </div>
                        </section>
                    </div>
                </aside>
                <div class="header-drawer-scrim"></div>
            </div>
        </section>
        <!-- Right nav -->
        <section class="header-nav-right">
            <!-- My store flyout -->
            <div class="header-my-store-section">
                <button id="header-my-store-button" aria-haspopup="true" aria-controls="header-my-store-menu"
                    aria-label="Open my store flyout" class="header-footer-icon-button" data-kmx-analytics="prop5=Header">
                    <!-- Location icon with alert -->
                    <svg class="header-footer-icon tablet-hidden desktop-hidden" width="40" height="40"
                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.29 11c0-5.35 4.36-9.71 9.71-9.71 5.35 0 9.71 4.12 9.71 9.71 0 5.569-6.153 9.57-8.207 10.905l-.023.015c-.45.29-.97.44-1.48.44s-1.03-.15-1.48-.44C8.48 20.6 2.29 16.35 2.29 11Zm8.97 9.711.03.019c.43.28.99.28 1.42 0 1.87-1.21 7.58-4.91 7.58-9.73 0-4.57-3.72-8.29-8.29-8.29-4.57 0-8.29 3.48-8.29 8.29 0 4.785 5.651 8.472 7.55 9.711ZM8.29 11c0-2.05 1.66-3.71 3.71-3.71s3.71 1.66 3.71 3.71-1.66 3.71-3.71 3.71S8.29 13.05 8.29 11Zm1.42 0c0 1.26 1.03 2.29 2.29 2.29s2.29-1.03 2.29-2.29S13.26 8.71 12 8.71 9.71 9.74 9.71 11Z" />
                        <circle class="hidden header-footer-location-icon-alert-circle" cx="19" cy="4" r="4"
                            stroke="white" stroke-width="1" />
                    </svg>
                    <span id="header-my-store-button-text">Find Your Store</span>
                    <!-- Caret icon -->
                    <svg class="header-footer-icon--secondary" width="16" height="16" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M7 10l5 5 5-5z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                </button>
                <div class="header-footer-menu-container">
                    <div id="header-my-store-menu" aria-modal="true" role="dialog"
                        aria-labelledby="header-my-store-button" class="header-footer-menu"></div>
                </div>
            </div>
            <!-- Saved cars link -->
            <button id="header-saved-cars-button" aria-label="My saved cars" class="header-footer-icon-button" data-kmx-analytics="prop5=Header">
                <!-- Favorite icon -->
                <svg class="header-footer-icon header-footer-icon--priority" width="40px" height="40px"
                    viewBox="8 8 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M20 28.64c-.27 0-.54-.04-.79-.13-4.22-1.41-8.92-6.48-8.92-11.51 0-3.68 2.32-5.44 4.3-5.82 1.82-.35 4.03.27 5.41 2.3 1.37-2.03 3.59-2.65 5.41-2.3 1.99.38 4.3 2.14 4.3 5.82 0 5.02-4.7 10.1-8.92 11.51-.26.09-.52.13-.79.13Zm-4.44-16.13c-.24 0-.47.02-.7.07-1.52.29-3.15 1.61-3.15 4.42 0 4.29 4.2 8.91 7.95 10.16.22.07.46.07.68 0 3.75-1.25 7.95-5.87 7.95-10.16 0-2.81-1.63-4.13-3.15-4.42-1.57-.3-3.57.41-4.48 2.69-.22.54-1.1.54-1.32 0-.78-1.95-2.36-2.75-3.78-2.75v-.01Z" />
                  </svg>
            </button>
            <!-- My profile menu -->
            <div class="header-my-profile-section">
                <button id="header-my-profile-button" aria-haspopup="true" aria-controls="header-my-profile-menu"
                    aria-label="Go to my profile page" class="header-footer-icon-button" data-kmx-analytics="prop5=Header">
                    <!-- Person icon -->
                    <svg class="header-footer-icon" width="40" height="40" viewBox="0 -3 24 31"
                        xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.29 7c0 2.6 2.11 4.71 4.71 4.71S16.71 9.6 16.71 7 14.6 2.29 12 2.29 7.29 4.4 7.29 7Zm1.42 0c0-1.81 1.48-3.29 3.29-3.29S15.29 5.19 15.29 7s-1.48 3.29-3.29 3.29S8.71 8.81 8.71 7ZM4.29 19c0 1.49 1.22 2.71 2.71 2.71h10c1.49 0 2.71-1.22 2.71-2.71v-1c0-2.6-2.11-4.71-4.71-4.71H9c-2.6 0-4.71 2.11-4.71 4.71v1Zm1.42-1c0-1.81 1.48-3.29 3.29-3.29h6c1.81 0 3.29 1.48 3.29 3.29v1c0 .71-.58 1.29-1.29 1.29H7c-.71 0-1.29-.58-1.29-1.29v-1Z" />
                    </svg>
                </button>
                <div class="header-footer-menu-container">
                    <div id="header-my-profile-menu" role="menu" aria-labelledby="header-my-profile-button"
                        class="header-footer-menu"></div>
                </div>
            </div>
        </section>
    </nav>
    <!-- Search bar -->
    <section id="header-search-bar" class="header-search-bar">
        <form id="header-search-bar-form" class="header-search-bar-form" role="search" method="GET" action="/cars">
            <fieldset class="header-search-bar-form-fieldset">
                <div class="header-search-bar-container">
                    <label for="header-inventory-search" class="visually-hidden">Search make, model, or keyword</label>
                    <input id="header-inventory-search" name="search" class="header-search-bar-input" type="search"
                        placeholder="Search by make, model, or keyword"
                        aria-placeholder="Search by make, model, or keyword" autocomplete="off" value=""
                        maxlength="100" />
                    <div class="header-search-bar-button-container">
                        <button id="header-search-button" aria-label="Submit search by Make, Model, or Keyword"
                            type="submit" class="header-footer-icon-button" data-kmx-analytics="noClickTrack">
                            <!-- Search icon -->
                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true">
                                <path
                                    d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z">
                                </path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </svg>
                        </button>
                    </div>
                    <div class="header-search-animated-categories">
                        <div aria-hidden="true" class="header-search-animated-category">make</div>
                        <div aria-hidden="true" class="header-search-animated-category">model</div>
                        <div aria-hidden="true" class="header-search-animated-category">keyword</div>
                        <div aria-hidden="true" class="header-search-animated-category">make</div>
                        <div aria-hidden="true" class="header-search-animated-category">model</div>
                        <div aria-hidden="true" class="header-search-animated-category">keyword</div>
                        <div aria-hidden="true" class="header-search-animated-category">make</div>
                        <div aria-hidden="true" class="header-search-animated-category">model</div>
                        <div aria-hidden="true" class="header-search-animated-category">keyword</div>
                        <div aria-hidden="true" class="header-search-animated-category">make, model, or keyword</div>
                    </div>
                </div>
            </fieldset>
        </form>
        <div class="header-footer-menu-container">
            <div id="header-search-bar-menu" class="header-footer-menu" tabindex="-1">
                <ul id="header-search-bar-list" class="header-footer-menu-list" role="menu" aria-hidden="true"
                    aria-orientation="vertical"></ul>
            </div>
        </div>
    </section>
</header>`;