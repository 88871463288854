import { content } from '../../content';

export interface IStoreHourMenu {
    handleStoreStatusButtonClick(): void;
}

export default class StoreHourMenu implements IStoreHourMenu {
    private showMenu = false;

    public handleStoreStatusButtonClick() {
        this.showMenu = !this.showMenu;
        const display = this.showMenu ? content.CLASSNAMES.visible : content.CLASSNAMES.hidden;

        let storeStatusMenuEl = document.getElementsByClassName(
            content.HEADER_FOOTER_FLYOUT_MENU.storeHoursMenuClass
        )[0];

        if (storeStatusMenuEl) {
            storeStatusMenuEl.className = content.HEADER_FOOTER_FLYOUT_MENU.storeHoursMenuClass + ' ' + display;
        }

        let storeStatusButtonEl = document.getElementsByClassName(
            content.HEADER_FOOTER_FLYOUT_MENU.storeHoursButtonClass
        )[0];

        if (storeStatusButtonEl) {
            storeStatusButtonEl.className =
                content.HEADER_FOOTER_FLYOUT_MENU.storeHoursButtonClass +
                ' ' +
                (this.showMenu ? content.CLASSNAMES.visible : '');
        }
    }
}
