import { content } from '../../content';

const buildDesktopCareersLink : any = () => {
    let careersListItem = document.createElement('li');
    careersListItem.className = content.MORE_LINKS.careersLink.desktop.class;
    careersListItem.setAttribute('role','menuitem')
    careersListItem.setAttribute('tabindex','-1')
    careersListItem.innerHTML = content.MORE_LINKS.careersLink.desktop.innerHTML;
    return careersListItem;
}

const addDesktopCareersLink = () => {
    let moreMenuList = document.querySelector('#header-helpful-links-menu .header-footer-menu-list') as HTMLElement;
    let careersLink = document.querySelector('#header-helpful-links-menu .header-link__careers-link') as HTMLElement;

    if(moreMenuList && !careersLink) {
        moreMenuList.appendChild(buildDesktopCareersLink());
    }
}

const buildMobileCareersLink = () => {
    let careersLink = document.createElement('a');
    let linkText = document.createTextNode(`${content.MORE_LINKS.careersLink.label}`);
    careersLink.id = content.MORE_LINKS.careersLink.mobile.id;
    careersLink.className = content.MORE_LINKS.careersLink.mobile.class;
    careersLink.href = content.MORE_LINKS.careersLink.href;
    careersLink.setAttribute("data-dialog", "focus-end");
    careersLink.appendChild(linkText);
    return careersLink;
}

const addMobileCareersLink = () => {
    let moreLinksSection = document.querySelector('#header-helpful-links-menu .header-footer-menu-list') as HTMLElement;
    let careersLink = document.querySelector('#header-helpful-links-menu .header-link__careers-link') as HTMLElement;

    if(moreLinksSection && !careersLink) {
        moreLinksSection.append(buildMobileCareersLink());
    }
}

export const setMobileContent = () => {
    removeCareersLinks();
    addMobileCareersLink();
}

export const setDesktopContent = () => {
    removeCareersLinks();
    addDesktopCareersLink();
}

const removeCareersLinks = () => {
    let careersLink = document.querySelector('.header-link__careers-link') as HTMLElement;
    if(careersLink) {
        careersLink.remove();
    }
}
