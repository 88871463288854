import activeTests from "./activeTests";
import * as constants from '../constants';
import { content } from "../content";

function onEventFired() {
    activeTests.activateTest(constants.SEARCH_BAR_TEST);

    const input = document.getElementById(content.HEADER_SEARCH.input.id);
    if (input) {
        input.setAttribute('placeholder', 'Search CarMax');
        input.setAttribute('aria-placeholder', 'Search CarMax');
    }
}

export function subscribe() {
    // Note: Fired by adobe target test 'S&R Search Bar Test'.
    document.documentElement.addEventListener(constants.ENABLE_SEARCH_BAR_TEST, ((
        event: CustomEvent
    ) => {
        onEventFired();
    }) as EventListener);
}
