import DelayedListMenu from '../menu/DelayedListMenu';
import ISeoLinkOptimizer from '../../utils/seo';
import { content } from '../../content';

export default class MoreLinksMenu extends DelayedListMenu {
    private seoLinkOptimizer?: ISeoLinkOptimizer; // optional because the constructor doesn't always call super()

    constructor(optimizer: ISeoLinkOptimizer) {
        let toggleButtonEl = document.querySelector(
            `#${content.HEADER.id} #${content.MORE_LINKS.button.id}`
        ) as HTMLElement;
        let menuEl = document.querySelector(`#${content.HEADER.id} #${content.MORE_LINKS.menuId}`) as HTMLElement;
        super(toggleButtonEl, menuEl, 'more');
        if (toggleButtonEl && menuEl) {
            this.seoLinkOptimizer = optimizer;
        }
    }

    buildMenuHTML() {
        return content.MORE_LINKS.defaultMenu.buildInnerHTML(
            this.seoLinkOptimizer && this.seoLinkOptimizer.shouldRenderSeoOptimizedLinks()
        );
    }


}
