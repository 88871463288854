import { content } from '../../content';
import { IPayload } from '../../types/analytics';
import { ANALYTICS_CONSTANTS, track } from '../../utils/analytics';
import { determineSection } from '../storeChooser/storeChooserSearch';
import DialogMenu from './DialogMenu';

export interface IDelayedDialogMenu {
    handleMouseover(): void;
    render(force: boolean): void;
}

export default abstract class DelayedDialogMenu extends DialogMenu implements IDelayedDialogMenu {
    private _rendered: boolean;
    menuName: string;

    constructor(toggleButtonEl: HTMLElement, menuEl: HTMLElement, menuName: string, closeButtonEl?: HTMLElement, ) {
        super(toggleButtonEl, menuEl, closeButtonEl);
        this._rendered = false;
        this.menuName = menuName;
    }

    public handleOpenButtonClick(): void {
        this.render();
        super.handleOpenButtonClick();
    }

    public handleOpenButtonKeydown(event: KeyboardEvent) {
        this.render();
        super.handleOpenButtonKeydown(event);
    }

    public handleMouseover() {
        this.render();
    }

    abstract buildMenuHTML(): string;

    public render(force = false): void {
        if (!this._rendered || force) {
            this._rendered = true;
            this.menuEl.innerHTML = this.buildMenuHTML();

            const menuLinks = this.menuEl.querySelectorAll('a');
            menuLinks.forEach((link)=>{
                link.addEventListener('click', (e:Event)=>{
                    const clickedEl = e.currentTarget as HTMLAnchorElement;
                    const location = determineSection(clickedEl);
                    const name = clickedEl.innerText;
                    const event = name.toLowerCase().trim() === 'see cars at this store' ? ANALYTICS_CONSTANTS.EVENTS.searchInitiated : ANALYTICS_CONSTANTS.EVENTS.click;
                    const payload = {
                        "event": event,
                        "linkDetails": {
                            "name": name,
                            "position": `${location}-${this.menuName}`
                        }
                    } as IPayload;
                    track(payload);
                })
            })


            const headerStoreSearchForm = this.menuEl.querySelector(`#${content.HEADER_MY_STORE.storeSearchFormId}`)  as HTMLFormElement;
            headerStoreSearchForm?.addEventListener('submit',(e)=>{
                const data = new FormData(headerStoreSearchForm);
                const location = determineSection(e.currentTarget as HTMLElement);
                const payload = {
                    "event": ANALYTICS_CONSTANTS.EVENTS.storeChange,
                    "linkDetails" : {
                        "name": data?.get('keyword'),
                        "position": `${location}-${this.menuName}`
                    }
                } as IPayload;
                track(payload);
            })


            const footerStoreSearchForm = this.menuEl.querySelector(`#${content.FOOTER_MY_STORE.storeSearchFormId}`) as HTMLFormElement;
            footerStoreSearchForm?.addEventListener('submit',(e)=>{
                const data = new FormData(footerStoreSearchForm);
                const payload = {
                    "event": ANALYTICS_CONSTANTS.EVENTS.storeChangeFooter,
                    "linkDetails": {
                        "name": data?.get('keyword'),
                        "position": `footer-${this.menuName}`
                    }
                } as IPayload;
                track(payload);
            })
        }
    }
}
