import { IAlertBannerIconTypes } from './types';

export const ONE_MONTH = 2592000000;

export const constants = {
    STORE_DISPLAY_NAME_TEMPLATE: '{Store.Name}',
    MAXIMUM_LOCAL_STORAGE_DISMISSED_BANNERS: 28,
    DISMISSED_ALERT_BANNER_LOCAL_STORAGE_EXPIRATION: ONE_MONTH,
    DISMISSED_ALERT_BANNERS_LOCAL_STORAGE_KEY: 'kmx-dismissed-alert-banners',
    REGISTER_ALERT_BANNERS_CUSTOM_EVENT: 'kmxRegisterAlertBanners',
};

const colors = {
    white: '#ffffff',
    slate100: '#f0f1f2',
    yellow600: '#ffd900',
};

const iconSize = '24px';

export const content = {
    alertBanner: {
        id: 'alert-banner',
        tallClass: 'tall',
        urgentClass: 'urgent',
        darkClass: 'dark',
        containerClass: 'alert-banner-container',
        detailsClass: 'alert-banner-details',
        messageClass: 'alert-banner-message',
        descriptionClass: 'alert-banner-description',
    },
    icons: {
        baseClass: 'alert-banner-icon',
        infoIconClass: 'alert-banner-info-icon',
        cleaningIconClass: 'alert-banner-cleaning-icon',
        storefrontIconClass: 'alert-banner-storefront-icon',
        closeIconClass: 'alert-banner-close-icon',
        width: iconSize,
        height: iconSize,
        fill: colors.yellow600,
        darkFill: colors.slate100,
        urgentFill: colors.white,
    },
    closeButton: {
        id: 'alert-banner-close-button',
        class: 'alert-banner-close-button',
        iconWidth: iconSize,
        iconHeight: iconSize,
        iconFill: colors.white,
    },
};

export const icons = {
    [IAlertBannerIconTypes.Info]: (fill: string) =>
        `<svg fill="${fill}" class="${content.icons.baseClass} ${content.icons.infoIconClass}" width="${content.icons.width}" height="${content.icons.height}" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M0 0h24v24H0z" fill="none"></path><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path></svg>`,
    [IAlertBannerIconTypes.Cleaning]: (fill: string) =>
        `<svg class="${content.icons.baseClass} ${content.icons.cleaningIconClass}" width="${content.icons.width}" height="${content.icons.height}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 18 24"  aria-hidden="true"><defs><mask id="af3fd981-53b8-46f6-a894-5f91767dff75" x="1.36" y="9.02" width="8.96" height="14.99" maskUnits="userSpaceOnUse"><g transform="translate(-5 0)"><g><polygon points="6.36 9.02 15.32 9.02 15.32 24 6.36 24 6.36 9.02" fill="${fill}" fill-rule="evenodd"/></g></g></mask><mask id="bef32b73-e93e-4a9d-8af1-256f183b6298" x="0" y="0" width="12.75" height="7.01" maskUnits="userSpaceOnUse"><g transform="translate(-5 0)"><g><polygon points="5 0 17.75 0 17.75 7.01 5 7.01 5 0" fill="${fill}" fill-rule="evenodd"/></g></g></mask></defs><g><g><g><g mask="url(#af3fd981-53b8-46f6-a894-5f91767dff75)"><path d="M13,16.15a6.72,6.72,0,0,1,.51-2.23,20.73,20.73,0,0,1,1.11-2.39,3,3,0,0,0-.49-.46A3.51,3.51,0,0,1,12.8,9H8.74l-.23.18a6.38,6.38,0,0,0-2,3.29,41,41,0,0,0,0,7.18,6.22,6.22,0,0,0,1.9,4.22.47.47,0,0,0,.32.12h5.61a.47.47,0,0,0,.38-.36q.3-1.14.51-2.1l.08-.39h-.06a6.54,6.54,0,0,1-2.28-5" transform="translate(-5 0)" fill="${fill}" fill-rule="evenodd"/></g></g><path d="M21.1,17.53a3.4,3.4,0,0,1-1.84,1.28l-.64-2.09a2.1,2.1,0,0,0,1-.62A1.94,1.94,0,0,0,20,15l1.9.15a4.07,4.07,0,0,1-.78,2.36M19.06,8a40.76,40.76,0,0,0-2.9,4.65,15.48,15.48,0,0,0-.72,1.61,4.21,4.21,0,0,0,1.27,4.91,3.56,3.56,0,0,0,1.36.71,3.34,3.34,0,0,0,1,.14A4.11,4.11,0,0,0,23,15.76c0-1-.7-3.14-3.94-7.75" transform="translate(-5 0)" fill="${fill}" fill-rule="evenodd"/><g><g mask="url(#bef32b73-e93e-4a9d-8af1-256f183b6298)"><path d="M8.69,7h4.12a5.75,5.75,0,0,1,.45-2.39A5.2,5.2,0,0,0,15.72,6a2.6,2.6,0,0,0,.88,0l.29-.53c0-.24-.33-.32-.52-.49A5.37,5.37,0,0,1,14.9,2.81a9.42,9.42,0,0,1,2-.81l.88-.33V0H9.12A6.08,6.08,0,0,0,5,3.51c1.88.32,3.69.56,3.69,3.5" transform="translate(-5 0)" fill="${fill}" fill-rule="evenodd"/></g></g></g></g></svg>`,
    [IAlertBannerIconTypes.Storefront]: (fill: string) =>
        `<svg class="${content.icons.baseClass} ${content.icons.infoIconClass}" width="${content.icons.width}" height="${content.icons.height}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 21" aria-hidden="true"><g><g><path d="M12,1.5l8.25,8.39H24V22.5H0V17.19H6V22.5H8V13.18l4-4.12,4,4.12V22.5h2V17.19h6v-2H18V12.36L12,6.21,6,12.36v2.83H0V9.89H3.75Z" transform="translate(0 -1.5)" fill="${fill}" fill-rule="evenodd"/></g></g></svg>`,
    [IAlertBannerIconTypes.Close]: (fill: string) =>
        `<svg fill="${fill}" class="${content.icons.closeIconClass}" width="${content.closeButton.iconWidth}" height="${content.closeButton.iconHeight}" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>`,
};
