import * as dialogMenuKeyboardHandler from './dialogMenuKeyboardHandler';
import Menu from './Menu';

export interface IDialogMenu {
    closeButtonEl?: HTMLElement;
}

export default class DialogMenu extends Menu implements IDialogMenu {
    private _toggleButtonEl: HTMLElement;
    private _menuEl: HTMLElement;
    private _closeButtonEl?: HTMLElement;
    private _keyboardHandler: any;

    constructor(toggleButtonEl: HTMLElement, menuEl: HTMLElement, closeButtonEl?: HTMLElement) {
        super();
        this._toggleButtonEl = toggleButtonEl;
        this._menuEl = menuEl;
        this._closeButtonEl = closeButtonEl;
        this._keyboardHandler = dialogMenuKeyboardHandler;
    }

    get toggleButtonEl(): HTMLElement {
        return this._toggleButtonEl;
    }

    get menuEl(): HTMLElement {
        return this._menuEl;
    }

    get closeButtonEl(): HTMLElement | undefined {
        return this._closeButtonEl;
    }

    get keyboardHandler(): any {
        return this._keyboardHandler;
    }
}
