interface IEnv {
    prod: boolean;
    qa: boolean;
    dev: boolean;
}
type Env = IEnv;

export const get = (hostname: string = window.location.hostname): Env => {
    return {
        prod: !/(qa|local|dev)/.test(hostname),
        qa: /(qa)/.test(hostname),
        dev: /(local|dev)/.test(hostname),
    };
};

export const getCarMaxBaseUrl = () => {
    const subdomain = get().prod ? 'www' : 'wwwqa';
    const url = `https://${subdomain}.carmax.com`;
    return url;
};

export const getCAFBaseUrl = () => {
    const subdomain = get().prod ? 'www4' : 'www4qa';
    const url = `https://${subdomain}.carmax.com`;
    return url;
};

export const getMyKmxBaseUrl = () => {
    return get().prod ? 'https://www.carmax.com' : 'http://localhost:5000';
}

export const getQueryStringValue = (paramName: string, url: string = window.location.href) => {
    const regex = new RegExp('[?&]' + paramName + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);

    if (!results || !results[2]) {
        return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
