interface IOptions {
    url: string;
    apiKey?: string;
    withCredentials?: boolean;
}
export type Options = IOptions;

export const get = (options: Options, success: (res: any) => any, error: (error: any) => any) => {
    if (success && typeof success === 'function' && error && typeof error === 'function') {
        try {
            let { url, apiKey, withCredentials } = options;
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url);
            xhr.setRequestHeader('Accept', 'application/json');
            if (withCredentials) {
                xhr.withCredentials = withCredentials;
            }
            if (apiKey) {
                xhr.setRequestHeader('ApiKey', apiKey);
            }
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200 && xhr.response) {
                        const response: any = JSON.parse(xhr.response);
                        success(response);
                    } else if (xhr.status === 204) {
                        success(null);
                    } else {
                        error(xhr.response);
                    }
                }
            };
            xhr.send();
        } catch (e) {
            error(e);
        }
    }
};
