export function getStartEndTime(time: { days: number; hours: number; minutes: number } | string ) {
    if(typeof time == "string") {
        const splitTime = time.split(":");
        return {
            days: undefined,
            hours: parseInt(splitTime[0]),
            minutes: parseInt(splitTime[1])
        };
    } 
    return time;
}