import { defaultMarkup } from '../../../../generated_files/headerMarkup';
import { initDesktopHeaderTracking } from '../utils/analytics';

interface IHeaderAttributes {
    theme?: string | null;
}

const htmlMarkup = ({ theme = '' }: IHeaderAttributes) => defaultMarkup(theme);

class Header extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const theme = this.getAttribute('data-theme');
        this.innerHTML = htmlMarkup({ theme });
        setTimeout(() => {
            const header = this.querySelector('#header');
            header!.setAttribute('style', 'display:block');
        }, 100);

        initDesktopHeaderTracking(this);
    }
}

const KMX_GLOBAL_HEADER = 'kmx-global-header';
if (customElements.get(KMX_GLOBAL_HEADER) === undefined) {
    customElements.define(KMX_GLOBAL_HEADER, Header);
} else {
}
