import { NearestStoreLocalStorage } from '../types/storage';
import { Store } from '../types/store';

const FIVE_MINUTES = 300000;

export const NEAREST_STORE = {
    key: 'kmx-nearest-store',
    expiration: FIVE_MINUTES,
};

export const getStore = (id: Store['Id']): Store | null => {
    try {
        let serializedNearestStore = localStorage.getItem(NEAREST_STORE.key);

        if (serializedNearestStore) {
            let nearestStoreLocalStorage: NearestStoreLocalStorage = JSON.parse(serializedNearestStore);

            if (
                !nearestStoreLocalStorage.expires ||
                (nearestStoreLocalStorage.expires && nearestStoreLocalStorage.expires < new Date().getTime())
            ) {
                localStorage.removeItem(NEAREST_STORE.key);
            }

            if (nearestStoreLocalStorage.store.Id === id) {
                return nearestStoreLocalStorage.store;
            }
        }
        return null;
    } catch (e) {
        console.error('Error getting nearest store from localStorage: ', e);
        return null;
    }
};

export const setStore = (store: Store): void => {
    try {
        const nearestStoreLocalStorage: NearestStoreLocalStorage = {
            store: store,
            expires: new Date().getTime() + NEAREST_STORE.expiration,
        };

        localStorage.setItem(NEAREST_STORE.key, JSON.stringify(nearestStoreLocalStorage));
    } catch (e) {
        console.error('Error saving nearest store to localStorage: ', e);
    }
};

export const getRecentSearchesFromLocalStorage = () => {
    const recentSearchesString = window && window.localStorage && window.localStorage.getItem('kmx-recent-searches');
    return recentSearchesString ? JSON.parse(recentSearchesString) : [];
};
