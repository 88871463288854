import * as constants from '../constants/seo';

export interface ISeoLinkOptimizer {
    enableSeoOptimizedLinks: boolean;
    optimizeServerRenderedLinks(): void;
    shouldRenderSeoOptimizedLinks(): boolean;
};

export default class SeoLinkOptimizer implements ISeoLinkOptimizer {
    enableSeoOptimizedLinks: boolean;

    constructor() {
        this.enableSeoOptimizedLinks = constants.ENABLE_SEO_OPTIMIZED_LINKS;
    }

    public optimizeServerRenderedLinks(): void {
        if (!this.shouldRenderSeoOptimizedLinks()) { return }

        const footerLinks = document.querySelectorAll('.footer-link');
        footerLinks.forEach(el => {
            if (this.shouldOptimizeLink(el)) {
                this.addNofollow(el);
            }
        });
    }

    public shouldRenderSeoOptimizedLinks(): boolean {
        return (this.enableSeoOptimizedLinks && window.location.pathname !== '/');
    }

    private shouldOptimizeLink(element: Element): boolean {
        if (constants.SEO_OPTIMIZED_LINKS.findIndex(link => link === element.getAttribute('href')) > -1) {
            return true;
        }
        return false;
    };

    private addNofollow(element: Element): void {
        element.setAttribute('rel', 'nofollow');
    };
}
