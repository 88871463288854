interface IActiveTests {    
    activateTest: (name: string, data: object | undefined) => void;
    determineIsActive: (name: string) => boolean; 
}

interface IActiveTest {
    name: string;
    data: object | undefined;
}

class ActiveTests implements IActiveTests {    
    private static instance: ActiveTests;
        
    private activeTests: IActiveTest[] = [];
    
    private constructor() {

    }

    static getInstance() {
        return this.instance || (this.instance = new this());
    }

    activateTest(name: string, data: object | undefined = undefined) {
        this.activeTests.push({ 
            name, 
            data 
        });
    }

    determineIsActive(name: string) {
        return this.activeTests.some(t => t.name === name);
    }

    getTestData(name: string): object | undefined {
        const activeTest: IActiveTest | undefined = this.activeTests.find(t => t.name === name);
        if (!activeTest) {
            return undefined;
        }

        return activeTest.data;
    }
}

export default ActiveTests.getInstance();
