import { addCustomElement, filterAuthorizedMenuItems, generateTopLevelHeaderTags, titleAsId } from '../utils';
import { appElId } from '../constants';
import { IMenuData, navStore } from "../store";
import { data } from '../data';
import { ThumbBarItemHighlightAction } from '../types'

const componentLevelCSSPrefix = `${appElId}__thumb-bar__item`;


export const toggleButtonHighlight = (action: ThumbBarItemHighlightAction, button: HTMLButtonElement | null) => {
    const highlightClass = `${componentLevelCSSPrefix}__button--highlighted`;
    const activeClass = 'kmx-hf__thumb-bar__item--active';
    if (button) {
        if (action === ThumbBarItemHighlightAction.HIGHLIGHT) {
            button.classList.add(highlightClass);
            button.classList.remove(activeClass);
        } else if ( action === ThumbBarItemHighlightAction.SELECT) {
            button.classList.add(highlightClass);
            button.classList.add(activeClass);
        } else if ( action === ThumbBarItemHighlightAction.INACTIVE ) {
            button.classList.remove(highlightClass);
            button.classList.remove(activeClass);
        }
    }
}

export class ThumbBarItem extends HTMLElement {

    constructor() {
        super();
    }

    connectedCallback() {

        const { icon, title, kmxAnalytics } = this.dataset;
        const { isThumbNavMenuOpen} = navStore.getState() as IMenuData;
        const navEl = document.querySelector('.kmx-hf__thumb-bar');

        this.innerHTML = `
            <div class="${componentLevelCSSPrefix}">

                <button
                id="kmx-thumb-bar__item__button-${titleAsId(title!)}"
                type="button"
                class="${componentLevelCSSPrefix}__button ${componentLevelCSSPrefix}__button--highlighted"
                aria-label="Open ${title} menu."
                aria-controls="subMenuContainer"
                aria-expanded="false"
                aria-haspopup="true"
                data-title="${title}"
                data-kmx-analytics="${generateTopLevelHeaderTags(title)}${this.buildAnalytics(kmxAnalytics)}">
                    ${ icon && `<div class="${componentLevelCSSPrefix}__icon">${atob(icon)}</div>`}
                    <div class="${componentLevelCSSPrefix}__title">${title}</div>
                </button>

            </div>
        `;

        navStore.subscribe(({currentTitle, activeTestVariant}: any, prev: any) => {

            const thisButton : HTMLButtonElement | null = this.querySelector(`.${componentLevelCSSPrefix}__button`);

            if (!currentTitle && ( activeTestVariant === "B" || activeTestVariant === "C" ) ) {
                thisButton!.setAttribute('aria-expanded', 'false');
                return toggleButtonHighlight(ThumbBarItemHighlightAction.INACTIVE, thisButton);
            }

            if (currentTitle === undefined && thisButton) {
                toggleButtonHighlight(ThumbBarItemHighlightAction.HIGHLIGHT, thisButton);
                thisButton!.setAttribute('aria-expanded', 'false');
            } else if ( currentTitle === title ) {
                toggleButtonHighlight(ThumbBarItemHighlightAction.SELECT, thisButton);
                thisButton!.setAttribute('aria-expanded', 'true');
            } else if (currentTitle !== title) {
                toggleButtonHighlight(ThumbBarItemHighlightAction.INACTIVE, thisButton);
                thisButton!.setAttribute('aria-expanded', 'false');
            }

        });
    }

    buildAnalytics(kmxAnalytics:string | undefined) {
        if ( kmxAnalytics && String(kmxAnalytics) !== "undefined" ) {
            return ` | ${kmxAnalytics}`;
        } else {
            return '';
        }
    }

    renderSeoMenu(isSignedIn: boolean, title: string | undefined) {
        const subMenuItems = data.menuItems.filter(item => item.title === title)[0].submenu;
        if (subMenuItems) {
            return (
                subMenuItems.
                    filter(item => filterAuthorizedMenuItems(isSignedIn, item))
                    .map(({ text, url }) => {
                        return `<li class="${componentLevelCSSPrefix}__seoSubMenu__item"><a tabindex="-1" href="${url}">${text}</a></li>`;
                    })
                    .join('')
            );
        } else {
            return '';
        }
    }

}

const KMX_THUMB_BAR_ITEM = 'kmx-thumb-bar-item';

addCustomElement(KMX_THUMB_BAR_ITEM, ThumbBarItem);
