import * as listMenuKeyboardHandler from './listMenuKeyboardHandler';
import Menu from './Menu';

export interface IListMenu {
    toggleButtonEl: HTMLElement;
    menuEl: HTMLElement;
    menuListEl?: HTMLElement;
}

export default class ListMenu extends Menu implements IListMenu {
    private _toggleButtonEl: HTMLElement;
    private _menuEl: HTMLElement;
    private _menuListEl?: HTMLElement;
    private _keyboardHandler: any;

    constructor(toggleButtonEl: HTMLElement, menuEl: HTMLElement, menuListEl?: HTMLElement) {
        super();
        this._toggleButtonEl = toggleButtonEl;
        this._menuEl = menuEl;
        this._menuListEl = menuListEl;
        this._keyboardHandler = listMenuKeyboardHandler;
    }

    get toggleButtonEl(): HTMLElement {
        return this._toggleButtonEl;
    }

    get menuEl(): HTMLElement {
        return this._menuEl;
    }

    get keyboardHandler(): any {
        return this._keyboardHandler;
    }

    get menuListEl(): HTMLElement | undefined {
        if (this._menuListEl) {
            return this._menuListEl;
        }
    }

    set menuListEl(el: HTMLElement | undefined) {
        this._menuListEl = el;
    }
}
