import * as searchApi from './searchApi';
import * as utils from './utils';
import * as constants from '../../constants';
import SearchSuggestionsMenu from './SearchSuggestionsMenu';
import { content } from '../../content';
import { getQueryStringValue } from '../../utils/environment';
import activeTests from '../../targetTests/activeTests';
import { SearchResults, UnavailableModelSuggestions } from '../../types/search';

export interface IInventorySearch {
    attachSetCognitiveSearchListener(): void;
    attachSetUnavailableModelSuggestionsListener(): void;
    handleInput(event: Event): void;
    handleSubmit(event: Event): void;
    handleInputKeydown(event: KeyboardEvent): void;
    handleMenuKeydown(event: KeyboardEvent): void;
    handleClickOutside(): void;
}

export default class InventorySearch implements IInventorySearch {
    private _menu: SearchSuggestionsMenu;

    constructor() {
        this.attachSetCognitiveSearchListener();
        this.attachSetUnavailableModelSuggestionsListener();
        this._menu = new SearchSuggestionsMenu(
            () => {
                this.setSearchBarActiveState(true);
            },
            () => {
                this.setSearchBarActiveState(false);
            }
        );
        this.preserveSearchBarPriorContents();

        const searchButton = document.querySelector('#header-search-button') as HTMLElement;
        if (searchButton != null) {
            searchButton.onclick = (e: MouseEvent) => this.handleSearchClick(e);
        }
    }

    public attachSetCognitiveSearchListener(): void {
        window.addEventListener(constants.SET_COGNITIVE_SEARCH_EVENT, () => {
            activeTests.activateTest(constants.COGNITIVE_SEARCH_TEST);
        });
    }

    public attachSetUnavailableModelSuggestionsListener(): void {
        window.addEventListener(constants.SET_UNAVAILABLE_MODEL_SUGGESTIONS_EVENT, (e) => {       
            const data = (e as CustomEvent).detail;
            activeTests.activateTest(constants.UNAVAILABLE_MODEL_SUGGESTIONS, data);
        });
    }

    isUnavailableModelSearch(searchTerm: string): boolean {
        if (!searchTerm || !searchTerm.trim() || !activeTests.determineIsActive(constants.UNAVAILABLE_MODEL_SUGGESTIONS)) {
            return false;
        }

        const testData = activeTests.getTestData(constants.UNAVAILABLE_MODEL_SUGGESTIONS);
        if (!testData || !Array.isArray(testData)) {
            return false;
        }

        const unavailableModelSuggestion = testData.find(st => searchTerm.toLowerCase().includes(st.searchText.toLowerCase()))
        if (!unavailableModelSuggestion) {
            return false;
        }

        return true;
    }

    handleInput(event: Event): void {
        const targetEl = event.target as HTMLInputElement;
        utils.debounce(() => {
            if (targetEl.value || targetEl === document.activeElement) {
                let unavailableModelSuggestionsApply = false;
                const searchTerm = targetEl.value ? targetEl.value.trim() : null;
                const testData = activeTests.getTestData(constants.UNAVAILABLE_MODEL_SUGGESTIONS);

                if (searchTerm && this.isUnavailableModelSearch(searchTerm) && testData) {
                    const unavailableModelSuggestions = testData as UnavailableModelSuggestions[];
                    const unavailableModelSuggestion = unavailableModelSuggestions.find(st => searchTerm.toLowerCase().includes(st.searchText.toLowerCase()))
                    if (unavailableModelSuggestion) {
                        unavailableModelSuggestionsApply = true;
                        const suggestions: SearchResults = {
                            unavailableModelSuggestions: unavailableModelSuggestion,
                            freeTextSearches: [],
                            recentSearches: [],
                            savedSearches: [],
                            length: 0,
                        };
                        this._menu.render(suggestions);
                    }
                }

                if (!unavailableModelSuggestionsApply) {
                    let params = '';
                    if (targetEl.value && activeTests.determineIsActive(constants.COGNITIVE_SEARCH_TEST)) {
                        params = 'useCognitiveSearch=true';
                    }

                    if (!activeTests.determineIsActive(constants.SEARCH_BAR_TEST)) {
                        searchApi.getSearchSuggestions(targetEl.value, params, searchSuggestionsList => {
                            if (searchSuggestionsList) {
                                this._menu.render(searchSuggestionsList);
                            }
                        });
                    }
                }
            } else {
                this._menu.render({
                    freeTextSearches: [],
                    length: 0,
                    savedSearches: [],
                    recentSearches: [],
                });
            }
        }, 300);
    }

    handleSubmit(event: Event): void {
        if (event) {
            event.preventDefault();

            const input = document.querySelector(`#${content.HEADER_SEARCH.input.id}`) as HTMLInputElement;
            // Prevent submission when it's an unavailable model search.
            if (input && !this.isUnavailableModelSearch(input.value)) {
                utils.updateSearchId();
                if (utils.isStockNumber(input.value)) {
                    utils.redirectToCarPage(input.value);
                } else {
                    const formEl = event.target as HTMLFormElement;
                    if (formEl) {
                        formEl.submit();
                    }
                }
            }
        }
    }

    handleInputKeydown(event: KeyboardEvent): void {
        const targetEl = event.target as HTMLInputElement;
        if (event.key === 'Enter' && targetEl && targetEl.value && this.isUnavailableModelSearch(targetEl.value)) {
            // Ignore enter key down for unavailable model search.
            event.preventDefault();
        } else {
            this._menu.handleInputKeydown(event);
        }
    }

    handleMenuKeydown(event: KeyboardEvent): void {
        this._menu.handleMenuKeydown(event);
    }

    handleClickOutside(): void {
        this._menu.handleClickOutside();
    }

    handleSearchClick(event: MouseEvent): void {
        const input = document.querySelector(`#${content.HEADER_SEARCH.input.id}`) as HTMLInputElement;
        if (input && input.value && this.isUnavailableModelSearch(input.value)) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private setSearchBarActiveState(active: boolean): void {
        const headerSearchBar = document.querySelector(`#${content.HEADER.id} #${content.HEADER_SEARCH.id}`);

        if (headerSearchBar) {
            if (active) {
                headerSearchBar.classList.add(content.HEADER_SEARCH.activeClass);
            } else {
                headerSearchBar.classList.remove(content.HEADER_SEARCH.activeClass);
            }
        }
    }

    private preserveSearchBarPriorContents() {
        // The search param will contain the last value the user searched on.
        const searchQueryStringValue = getQueryStringValue('search');

        const input = document.querySelector(`#${content.HEADER_SEARCH.input.id}`) as HTMLInputElement;
        if (input) {
            input.value = searchQueryStringValue;
        }
    }
}
