import { IAlertBannerData, IAlertBannerTypes, IAlertBannerIconTypes } from './types';
import { content, icons } from './constants';
import * as storage from './storage';
import * as utils from './utils';
import { Store } from '../../types/store';

export interface IAlertBanner {
    init(): void;
}

export default class AlertBanner implements IAlertBanner {
    private _nearestStore: Store | null;
    private _alertBannerDataList: IAlertBannerData[];
    private _matchingAlertBanner: IAlertBannerData | null;
    private _isRendering: boolean;

    constructor() {
        this._nearestStore = null;
        this._alertBannerDataList = [];
        this._matchingAlertBanner = null;
        this._isRendering = false;

        this.handleRegisterAlertBannersEvent = this.handleRegisterAlertBannersEvent.bind(this);
        this.handleHistoryPushStateEvent = this.handleHistoryPushStateEvent.bind(this);
        this.handleStoreChangeEvent = this.handleStoreChangeEvent.bind(this);
    }

    private handleRegisterAlertBannersEvent(alertBannerDataList: IAlertBannerData[]): void {
        utils.attachHistoryPushStateEventHandler(this.handleHistoryPushStateEvent);
        this._alertBannerDataList = alertBannerDataList;
        this.updateBanner();
    }

    private handleHistoryPushStateEvent(): void {
        this.updateBanner();
    }

    private handleStoreChangeEvent(store: Store): void {
        if (!this._nearestStore || this._nearestStore.Id !== store.Id) {
            this._nearestStore = store;
            this.updateBanner();
        }
    }

    private updateBanner(): void {
        this._matchingAlertBanner = this.findMatchingBanner();

        if (this._matchingAlertBanner && this._nearestStore) {
            this.render(this._matchingAlertBanner, this._nearestStore);
        } else {
            this.removeBanner();
        }
    }

    private findMatchingBanner(): IAlertBannerData | null {
        let matchingAlertBanner = null;

        if (this._nearestStore) {
            const matchingAlertBanners = utils.findMatchingAlertBanners(
                this._alertBannerDataList,
                utils.getPathname(),
                this._nearestStore.Id
            );

            if (matchingAlertBanners.length === 1) {
                const bannerId = matchingAlertBanners[0].id;
                const dismissedAlertBanners = storage.getDismissedAlertBanners();

                if (dismissedAlertBanners.allIds.indexOf(bannerId) === -1) {
                    matchingAlertBanner = matchingAlertBanners[0];
                }
            } else if (matchingAlertBanners.length > 1) {
                console.error('Multiple alert banners registered for page: ', matchingAlertBanners);
            }
        }

        return matchingAlertBanner;
    }

    private handleAlertBannerClose(id: IAlertBannerData['id']): void {
        this.removeBanner();
        storage.addDismissedAlertBanner(id);
    }

    private removeBanner(): void {
        const alertBanner = document.querySelector(`#${content.alertBanner.id}`);
        if (alertBanner && alertBanner.parentNode) {
            alertBanner.parentNode.removeChild(alertBanner);
        }
    }

    private render(alertBanner: IAlertBannerData, nearestStore: Store): void {
        if (!this._isRendering) {
            this._isRendering = true;
            let iconFill =
                alertBanner.type === IAlertBannerTypes.Dark
                    ? content.icons.darkFill
                    : alertBanner.type === IAlertBannerTypes.Urgent
                    ? content.icons.urgentFill
                    : content.icons.fill;
            let bannerThemeClass =
                alertBanner.type === IAlertBannerTypes.Dark
                    ? content.alertBanner.darkClass
                    : alertBanner.type === IAlertBannerTypes.Urgent
                    ? content.alertBanner.urgentClass
                    : '';
            let bannerSizeClass = !alertBanner.dismissable && !alertBanner.icon ? content.alertBanner.tallClass : '';

            const bannerHtml = `<aside id="${content.alertBanner.id}" class="${bannerThemeClass} ${bannerSizeClass}">
            <div class="${content.alertBanner.containerClass}">
                <div class="${content.alertBanner.detailsClass}">
                    ${alertBanner.icon ? icons[alertBanner.icon](iconFill) : ''}
                    <div class="${content.alertBanner.messageClass}">
                        <p class="${content.alertBanner.descriptionClass}">${utils.renderTextWithStoreName(
                alertBanner.body,
                nearestStore
            )}</p>
                    </div>
                </div>
                ${
                    alertBanner.dismissable === false
                        ? ''
                        : `<button id="${content.closeButton.id}" class="${
                              content.closeButton.class
                          }" aria-haspopup="false" aria-controls="alert-banner" aria-label="Dismiss alert banner">
                            ${icons[IAlertBannerIconTypes.Close](content.closeButton.iconFill)}
                        </button>`
                }
            </div>
        </aside>`;

            let existingBanner = document.querySelector(`#${content.alertBanner.id}`);
            if (existingBanner) {
                this.removeBanner();
            }

            let previousSiblingEl = document.querySelector(alertBanner.previousSiblingElSelector);
            if (previousSiblingEl) {
                previousSiblingEl.insertAdjacentHTML('afterend', bannerHtml);
                const alertBannerCloseButton = document.querySelector(
                    `#${content.alertBanner.id} #${content.closeButton.id}`
                ) as HTMLElement;
                if (alertBannerCloseButton) {
                    let id = alertBanner.id;
                    alertBannerCloseButton.onclick = () => {
                        this.handleAlertBannerClose(id);
                    };
                }
            }
            this._isRendering = false;
        }
    }

    public init(): void {
        utils.attachRegisterAlertBannersEventListener(this.handleRegisterAlertBannersEvent);
        utils.attachStoreChangeEventHandler(this.handleStoreChangeEvent);
    }
}
