import { content } from '../../content';
import * as constants from '../../constants';

export function closeModal() {
    const foundModal = document.querySelector(`#${content.STORE_CHOOSER.WRAPPER.id}`) as HTMLDivElement;
    if(foundModal) {
        foundModal.classList.remove(content.STORE_CHOOSER.WRAPPER.visibleClass);
    }
    const foundBodyElement = document.getElementsByTagName('body')[0] as HTMLBodyElement;
    const foundBaseHtmlElement = document.getElementsByTagName('html')[0] as HTMLHtmlElement;
    if(foundBodyElement) { foundBodyElement.classList.remove(content.CLASSNAMES.bodyScrollLock) };
    if(foundBaseHtmlElement) { foundBaseHtmlElement.classList.remove(content.CLASSNAMES.bodyScrollLock) };

    var event = new CustomEvent(constants.CLOSE_STORE_SELECTION_MODAL_EVENT);
    document.documentElement.dispatchEvent(event);
}
