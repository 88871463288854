import { content } from "../../content";
import { closeModal } from "./utils";

export const buildStoreChooserHeader = () => {
    // Create the header main element
    const headerHTML: HTMLElement = document.createElement('div');
    headerHTML.classList.add(content.STORE_CHOOSER.MODAL.HEADER.class);
    headerHTML.id = content.STORE_CHOOSER.MODAL.HEADER.id;
    // Create the title
    const titleHTML: HTMLElement = document.createElement('h2');
    titleHTML.className = content.STORE_CHOOSER.MODAL.HEADER.TITLE.class;
    titleHTML.id = content.STORE_CHOOSER.MODAL.HEADER.TITLE.id;
    titleHTML.innerText = content.STORE_CHOOSER.MODAL.HEADER.TITLE.innerText
    // Create the title
    const hiddenDescriptionHTML: HTMLElement = document.createElement('p');
    hiddenDescriptionHTML.className = content.STORE_CHOOSER.MODAL.HEADER.DESCRIPTION.class;
    hiddenDescriptionHTML.id = content.STORE_CHOOSER.MODAL.HEADER.DESCRIPTION.id;
    hiddenDescriptionHTML.innerText = content.STORE_CHOOSER.MODAL.HEADER.DESCRIPTION.innerText
    // Bring the title and close icon together
    headerHTML.appendChild(titleHTML);
    headerHTML.appendChild(hiddenDescriptionHTML);
    headerHTML.appendChild(buildCloseIcon());
    return headerHTML;
}

function buildCloseIcon() {


    // Create the outer button of the close icon
    const closeButtonHTML: HTMLElement = document.createElement('button');
    closeButtonHTML.classList.add(content.STORE_CHOOSER.MODAL.HEADER.CLOSE.class);
    closeButtonHTML.setAttribute('type', content.STORE_CHOOSER.MODAL.HEADER.CLOSE.type);
    closeButtonHTML.setAttribute('aria-label', content.STORE_CHOOSER.MODAL.HEADER.CLOSE.ariaLabel);
    closeButtonHTML.setAttribute('aria-hidden', content.STORE_CHOOSER.MODAL.HEADER.CLOSE.ariaHidden);    
    // add the click action associated
    closeButtonHTML.addEventListener('click', () => closeModal())
    // Include inner html for styling the svg
    closeButtonHTML.innerHTML =  content.STORE_CHOOSER.MODAL.HEADER.CLOSE.innerHTML;
    return closeButtonHTML;
}
