interface IStore extends IStoreLegacyFields {
    Id: string;
    Name: string;
    PhoneNumbers: PhoneNumber[];
    Hours: TimeRange[];
    PhoneHours: TimeRange[];
    ServiceHours: TimeRange[];
    Latitude: number;
    Longitude: number;
    AddressLine1: string;
    AddressLine2: string;
    City: string;
    MailingCity: string;
    StateAbbreviation: string;
    ZipCode: string;
    HolidayHours: IHolidayHours[];
    ActiveStatus: string;
    AlertMessage: string;
    Capabilities: string[];
    IsSaveable: boolean;
    IsCarBuyingCenter: boolean;
    DistanceInMiles: number;
}


interface ICamelCaseStore extends ICamelCaseStoreLegacyFields {
    id: string;
    name: string;
    phoneNumbers: PhoneNumber[];
    hours: TimeRange[];
    phoneHours: TimeRange[];
    serviceHours: TimeRange[];
    latitude: number;
    longitude: number;
    addressLine1: string;
    addressLine2: string;
    city: string;
    mailingCity: string;
    stateAbbreviation: string;
    zipCode: string;
    holidayHours: DateTimeRange[];
    activeStatus: string;
    alertMessage: string;
    capabilities: string[];
    distanceInMiles: number;
}

interface IStoreLegacyFields {
    Categories: [];
    DisplayName: '';
    GeneralTelephoneNumbers: [];
    IsTemporarilyClosed: false;
    Links: [];
    LocationDisplay: '';
    SalesTelephoneNumbers: [];
    ShowroomHours: [];
    State: '';
    Status: '';
    StatusAlert: '';
    Subtitle: '';
    TelephoneHours: [];
    TelephoneNumber: '';
    TelephoneNumbers: [];
    TollFreePhoneNumber: '';
}

interface ICamelCaseStoreLegacyFields {
    categories: [];
    displayName: '';
    generalTelephoneNumbers: [];
    isTemporarilyClosed: false;
    isSaveable: true,
    isCarBuyingCenter: false,
    links: [];
    locationDisplay: '';
    salesTelephoneNumbers: [];
    showroomHours: [];
    state: '';
    status: '';
    statusAlert: '';
    subtitle: '';
    telephoneHours: [];
    telephoneNumber: '';
    telephoneNumbers: [];
    tollFreePhoneNumber: '';
}

interface IPhoneNumber {
    type: string;
    number: string;
    formatted: string;
}

// There's already a StoreStatus used in the same area, and the proper WebStore endpoint is "StoreAvailability"
interface IStoreAvailability {
    Id: string;
    IsOpen: boolean;
    Holiday: DateTimeRange[];
    CurrentHours: TimeRange[];
    Alert: IStoreAlert;
}

interface IStoreAlert {
    storeId: string;
    message: string;
    status: string;
}

export const STORE_CLOSED_STATUSES = ['Temporary Closure', 'Weather Closing', 'Power Outage', 'Closed', 'LP Investigative Shutdown'];

export function getStoreStatusDisplay(message: string) {
    switch (message) {
        case 'Temporary Closure':
            return 'Temporarily closed';
        case 'Weather Closing':
            return 'Closed due to severe weather';
        case 'Power Outage':
            return 'Closed due to power outage';
        case 'Delayed Opening':
            return 'Delayed opening';
        case 'Open (on Alert)':
            return 'Open (on Alert)'
        case 'Service/repair only':
            return 'Service/repair only'
        case 'By Appointment Only':
            return 'Appointment Only';
        case 'Closed':
        default:
            return 'Temporarily closed';
    }
}

export const isClosed = (message: string | null | undefined) => message && (STORE_CLOSED_STATUSES.includes(message) || message.indexOf('closed') > 0)

export function getDayOfWeekShort(dayIndex: number) {
    return ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'][dayIndex] || '';
}

export function getPrimaryPhoneNumber(store: Store) {
    const localPhoneNumbers = store.PhoneNumbers.filter(number => number.type == 'PhoneLocal');
    const phoneNumber = localPhoneNumbers.length > 0 ? localPhoneNumbers[0].formatted : '';
    return phoneNumber;
}

interface ITimeRange {
    startDayOfWeek: number;
    endDayOfWeek: number;
    startHour: { days: number; hours: number; minutes: number } | string;
    endHour: { days: number; hours: number; minutes: number } | string;
}

interface IDateTimeRange extends TimeRange {
    date: Date;
}

export interface IHolidayHours  {
    date: string,
    startTime: string | null,
    endTime: string | null
}

export type StoreAvailability = IStoreAvailability;
export type Store = IStore;
export type CamelCaseStore = ICamelCaseStore;
export type PhoneNumber = IPhoneNumber;
export type TimeRange = ITimeRange;
export type DateTimeRange = IDateTimeRange;
