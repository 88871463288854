import * as cookie from '../../storage/cookie';
import DelayedListMenu from '../menu/DelayedListMenu';
import { content } from '../../content';

export default class MyProfileMenu extends DelayedListMenu {
    constructor() {
        let toggleButtonEl = document.querySelector(
            `#${content.HEADER.id} #${content.MY_PROFILE.buttonId}`
        ) as HTMLElement;
        let menuEl = document.querySelector(`#${content.HEADER.id} #${content.MY_PROFILE.menuId}`) as HTMLElement;

        super(toggleButtonEl, menuEl,'profile');
    }

    buildMenuHTML() {
        let html = !cookie.isAuthenticated()
            ? content.MY_PROFILE.unauthenticatedMenu.innerHTML
            : cookie.hasCustomerHubStore()
            ? content.MY_PROFILE.authenticatedMenuWithHub.innerHTML
            : content.MY_PROFILE.authenticatedMenu.innerHTML;
        return html;
    }
}
