import { content, links } from '../../content';
import * as cookie from '../../storage/cookie';
import * as environment from '../../utils/environment';

export const render = (): void => {
    const navRightSectionEl = document.querySelector(`#${content.HEADER.id} .${content.HEADER.navRight.class}`);
    const savedCarsButtonEl = document.querySelector(`#${content.HEADER.id} #${content.SAVED_CARS_BUTTON.id}`);

    if (navRightSectionEl && savedCarsButtonEl) {
        if (cookie.isAuthenticated() || cookie.hasSignedIn()) {
            savedCarsButtonEl.classList.add(content.SAVED_CARS_BUTTON.visibleClass);
            navRightSectionEl.classList.add(content.HEADER.navRight.loggedInClass);
        } else {
            savedCarsButtonEl.classList.remove(content.SAVED_CARS_BUTTON.visibleClass);
            navRightSectionEl.classList.remove(content.HEADER.navRight.loggedInClass);
        }
    }
    return undefined;
};

export const handleClick = (windowRef: Window = window): void => {
    windowRef.location.href =
        environment.getCarMaxBaseUrl() +
        (cookie.isAuthenticated() || cookie.hasSignedIn() ? links.savedCars.href : links.watchlist.href);
};
