import { Store } from "../types/store";

export const determineSeeCarsUrl = (
    store: Store, 
    urlPathname: string = window.location.pathname, 
    urlQueryString: string = window.location.search) : string => {
    // Creates a url to which to navigate when the user clicks See Cars.
    let seeCarsUrl = '';
    if (store && store.Id) {
        seeCarsUrl = `/cars/${store.Id}`;

        // If already on a search page with filters, keep those filters.  Replace any existing store id filters.
        let pathname = ((urlPathname && urlPathname.toLowerCase()) || '');
        const queryString = ((urlQueryString && urlQueryString.toLowerCase()) || '');
        
        const hasQueryString = !!queryString;
        const isSearchRoute = 
            !pathname.startsWith('/cars/all') &&
            (pathname.startsWith('/cars/') || 
            (pathname.startsWith('/cars') && hasQueryString));
        if (isSearchRoute) {
            // Remove any other store filter in the url.                        
            pathname = pathname
                .replace(/\/\d{4}/g, '');
            seeCarsUrl = `${pathname}/${store.Id}${queryString}`;
        }
    }

    return seeCarsUrl;
}

/**
 * Builds a new post-store selection url
 */
export const determineNewStoreSelectionUrl = (
    urlPathname: string = window.location.pathname, 
    urlQueryString: string = window.location.search) : string => {
    // Creates a url to which to navigate after changing stores.
    // Need to eliminate location in the query string and in the url segments as it will change the selected store back to a store in that city. 
    if (!urlPathname && !urlQueryString) {
        return urlPathname;
    }

    const segments = urlPathname.split('/').filter(s => !s.toLowerCase().startsWith('location-'));
    const newPathname = segments.join('/');

    if (!urlQueryString) {
        return newPathname;
    }

    const queryStringParams = urlQueryString.replace('?', '').split('&');
    if (queryStringParams.length === 0) {
        return newPathname;
    }

    const newQueryString = queryStringParams
        .filter(p => !p.toLowerCase().startsWith('location='))
        .join('&');
    if (!newQueryString) {
        return newPathname;
    }

    return `${newPathname}?${newQueryString}`;
}